import { Badge, TabContainer, Text } from "./Tab.style";
import { Link } from "react-router-dom";

export interface TabProps {
  count?: number,
  isActive?: boolean,
  redirectUrl: string,
  text: string,
}

export const Tab = (props: TabProps) => {
  const { count, isActive = false, redirectUrl, text } = props;
  return (
    <Link to={redirectUrl}>
      <TabContainer isActive={isActive}>
        <Text isActive={isActive}>
          {text}
        </Text>
        { typeof count === 'number' && (
          <Badge isActive={isActive}>
            {count}
          </Badge>
        ) }
      </TabContainer>
    </Link>
  );
};
