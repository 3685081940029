import {TabsContainer} from "./Tabs.style";
import {Tab, TabProps} from "./molecules/Tab";

export interface TabsProps {
  items: Array<TabProps>,
}

export const Tabs = ({items}: TabsProps) => {
  return (
    <TabsContainer>
      {
        items.map((item, index) => (
          <Tab
            key={`tab${index}`}
            count={item.count}
            isActive={item.isActive}
            redirectUrl={item.redirectUrl}
            text={item.text} />
        ))
      }
    </TabsContainer>
  );
};
