import styled from "styled-components";
import { IconContainer } from "../../../components/textWithIcon/TextAndIcon.style";

export const TableCellContainer = styled.div`
  ${IconContainer} {
    margin-right: var(--space-base);
    svg {
      position: relative;
      top: 6px;
    }
  }
`;

export const DomainText = styled.p`
  margin-left: 43px;
  font-style: italic;
  font-size: 12px;
  color: var(--grey700);
`;
