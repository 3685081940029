import styled from "styled-components";

export const TextAndIconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const IconContainer = styled.div`
  margin-right: var(--space-s);
  font-size: 1.5em;
`;

export const TextContainer = styled.p<{fontSize: number}>`
  color: var(--grey900);
  font-size: ${(props) => props.fontSize}px;
  font-weight: 400;
  margin-bottom: 0;
  word-break: break-all;
  width: 100%;
`;
