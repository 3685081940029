import styled, { keyframes } from "styled-components";
import { sectionHeaderStyle } from "../../../components/GlobalStyle/GlobalStyle.style";
import { BaseErrorMessageContainer } from "../../../components/baseErrorMessage/BaseErrorMessage.style";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";
import {TextAndIconContainer, TextContainer} from "../../../components/textWithIcon/TextAndIcon.style";

export const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const FileListSidebarContainer = styled.div<{ hide: boolean }>`
  position: sticky;
  top: 20px;
  width: 315px;
  background-color: var(--white);
  padding: var(--space-base);
  border-radius: var(--border-radius);
  min-height: calc(100vh - 360px);

  ${BaseErrorMessageContainer} {
    background-color: transparent;
  }

  @media ${maxLayout.md} {
    position: fixed;
    width: 75vw;
    min-height: 100vh;
    border-radius: 0;
    padding: var(--space-l);
    top: 0;
    right: 0;
    z-index: 10;

    transform: ${(props) => props.hide ? 'translateX(75vw)' : 'translateX(0)'};
    transition: transform 350ms ease;
  }

  @media ${maxLayout.sm} {
    padding: var(--space-l) var(--space-base);
  }
`;

export const GuideContainer = styled.div`
  h4 {
    ${sectionHeaderStyle};
    margin-bottom: var(--space-base);
    color: var(--grey900);
  }
  p {
    font-size: 14px;
    margin-bottom: 12px;
    color: var(--grey900);
  }
  a {
    color: var(--primaryDarken);
    :hover {
      text-decoration: underline;
    }
  }
`;

export const FileInfoContainer = styled.div`
  ${TextAndIconContainer} {
    align-items: flex-start;
  }

  ${TextContainer} {
    ${sectionHeaderStyle};
    color: var(--grey900);
    line-height: 130%;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  svg {
    font-size: 0.9em;
  }
`;

export const DataMetaContainer = styled.div`
`;

export const MetaGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-s);
`;

export const MetaTitle = styled.div`
  flex: 0 0 35%;
  color: var(--grey700);
  text-align: left;
  font-weight: 500;
  font-size: 14px;
`

export const MetaContent = styled.div`
  flex: 0 0 62%;
  color: var(--grey900);
  font-size: 14px;

  span {
    animation: ${FadeIn} 120ms ease;
  }

  .fa-icon {
    font-size: 1.2em;
    position: relative;
    top: 1px;
    margin-right: var(--space-s);

    path {
      fill: var(--grey900);
    }
  }
`;

export const InputGroup = styled.div`
  margin-bottom: var(--space-s);
  animation: ${FadeIn} 120ms ease;

  p {
    margin-bottom: var(--space-s);
    color: var(--grey700);
    font-weight: 500;
    font-size: 14px;
  }
`;

export const SidebarOverlay = styled.div<{ hide: boolean }>`
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3;

  @media ${maxLayout.md} {
    display: ${(props) => props.hide ? 'none' : 'block'};
  }
`
