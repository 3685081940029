import {
  BaseErrorMessageContainer,
  ErrorDescription,
  ErrorMessage,
  ErrorTitle,
} from "./BaseErrorMessage.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface BaseErrorMessageProps {
  description?: string;
  errorMessage?: string | null;
  icon: "spinner" | "inbox" | "exclamation-triangle" | "search";
  title: String;
}

export const BaseErrorMessage = (props: BaseErrorMessageProps) => {
  const { description, errorMessage, icon, title } = props;
  const iconType = icon === "spinner" ? "fad" : "fas";

  return (
    <BaseErrorMessageContainer>
      <FontAwesomeIcon
        color={"#6c757d"}
        size={"3x"}
        pulse={icon === "spinner"}
        icon={[iconType, icon]}
      />
      <ErrorTitle>{title}</ErrorTitle>
      {description && <ErrorDescription> {description} </ErrorDescription>}
      {errorMessage && <ErrorMessage> ERROR: {errorMessage} </ErrorMessage>}
    </BaseErrorMessageContainer>
  );
};
