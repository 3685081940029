export enum size {
  xxs = 375,
  xs = 480,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1440,
  xxxl = 1920,
  xxxxl = 2560,
}

// Show when width > selected size
export const maxLayout = {
  xxs: `(max-width: ${size.xxs - 0.02}px)`,
  xs: `(max-width: ${size.xs - 0.02}px)`,
  sm: `(max-width: ${size.sm - 0.02}px)`,
  md: `(max-width: ${size.md - 0.02}px)`,
  lg: `(max-width: ${size.lg - 0.02}px)`,
  xl: `(max-width: ${size.xl - 0.02}px)`,
  xxl: `(max-width: ${size.xxl - 0.02}px)`,
  xxxl: `(max-width: ${size.xxxl - 0.02}px)`,
  xxxxl: `(max-width: ${size.xxxxl - 0.02}px)`,
};

// Show when width < selected size
export const minLayout = {
  xxs: `(min-width: ${size.xxs}px)`,
  xs: `(min-width: ${size.xs}px)`,
  sm: `(min-width: ${size.sm}px)`,
  md: `(min-width: ${size.md}px)`,
  lg: `(min-width: ${size.lg}px)`,
  xl: `(min-width: ${size.xl}px)`,
  xxl: `(min-width: ${size.xxl}px)`,
  xxxl: `(min-width: ${size.xxxl}px)`,
  xxxxl: `(min-width: ${size.xxxxl}px)`,
};
