import { BaseErrorMessage } from "../baseErrorMessage/BaseErrorMessage";

interface EmptyMessageProps {
  /**
   * The error title
   * @default 'Unable to load data'
   * */
  title?: string,
  /**
   * The error description
   * @default 'There was an error while loading data. Please try again by reloading the page.'
   * */
  description?: string,
  /** An optional error message e.g. 404 Not found */
  errorMessage?: string | null,
}

export const ErrorMessage = (props: EmptyMessageProps) => {
  const {
    title = 'Unable to load data',
    description = 'There was an error while loading data. Please try again by reloading the page.',
    errorMessage,
  } = props;
  return (
    <BaseErrorMessage
      errorMessage={errorMessage}
      icon={'exclamation-triangle'}
      title={title}
      description={description}
    />
  );
};
