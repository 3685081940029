import styled, { css } from "styled-components";
import { ButtonStyle } from "./Button";

const primaryStyle = css`
  color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.primary};
`;

const secondaryAStyle = css`
  color: ${(props) => props.theme.colors.grey800};
  border: 1px solid ${(props) => props.theme.colors.grey800};
  background-color: transparent;
`;

const secondaryBStyle = css`
  color: ${(props) => props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: transparent;
`;

const secondaryCStyle = css`
  color: ${(props) => props.theme.colors.grey800};
  border: 1px solid ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.white};
`;

const secondaryDStyle = css`
  color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey800};
  background-color: ${(props) => props.theme.colors.grey800};
`;

const dangerStyle = css`
  color: ${(props) => props.theme.colors.grey100};
  border: 1px solid ${(props) => props.theme.colors.red};
  background-color: ${(props) => props.theme.colors.red};
`;

export const ButtonWrapper = styled.button<{
  buttonStyle?: ButtonStyle;
  disable?: boolean;
  fullWidth?: boolean;
  isIconButton?: boolean;
}>`
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  padding: 8px ${(props) => (props.isIconButton ? "10px" : "15px")};
  border-radius: ${(props) => props.theme.borderRadius};
  width: ${(props) => (props.fullWidth ? "inherit" : "fit-content")};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  
  > svg {
    margin-right: var(--space-s);
  }

  ${(props) => {
    if (props.buttonStyle === "secondary-a") {
      if (props.disable) {
        return css`
          ${secondaryAStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${secondaryAStyle};
          :hover {
            cursor: pointer;
            color: ${(props) => props.theme.colors.white};
            background-color: ${(props) => props.theme.colors.grey800};
          }
        `;
      }
    } else if (props.buttonStyle === "secondary-b") {
      if (props.disable) {
        return css`
          ${secondaryBStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${secondaryBStyle};
          :hover {
            cursor: pointer;
            ${primaryStyle}
          }
        `;
      }
    } else if (props.buttonStyle === "secondary-c") {
      if (props.disable) {
        return css`
          ${secondaryCStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${secondaryCStyle};
          :hover {
            cursor: pointer;
            background-color: ${(props) => props.theme.colors.grey400};
            border-color: ${(props) => props.theme.colors.grey400};
          }
          :active {
            border-color: rgba(0, 166, 237, 0.4);
          }
        `;
      }
    } else if (props.buttonStyle === "secondary-d") {
      if (props.disable) {
        return css`
          ${secondaryDStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${secondaryDStyle};
          :hover {
            cursor: pointer;
            background-color: ${(props) => props.theme.colors.grey700};
            border-color: ${(props) => props.theme.colors.grey700};
          }
          :active {
            border-color: rgba(108, 117, 125, 0.4);
          }
        `;
      }
    } else if (props.buttonStyle === "danger") {
      if (props.disable) {
        return css`
          ${dangerStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${dangerStyle};
          :hover {
            cursor: pointer;
            background-color: ${(props) => props.theme.colors.red};
            border-color: ${(props) => props.theme.colors.red};
          }
          :active {
            border-color: rgba(108, 117, 125, 0.4);
          }
        `;
      }
    } else {
      if (props.disable) {
        return css`
          ${primaryStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${primaryStyle};
          :hover {
            cursor: pointer;
            background-color: ${(props) => props.theme.colors.middle};
            border-color: ${(props) => props.theme.colors.middle};
          }
          :active {
            border-color: rgba(45, 131, 219, 0.35);
          }
        `;
      }
    }
  }}
`;
