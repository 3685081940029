import {
  Container,
  HeaderContainer,
  Title,
  IconWrapper,
} from "./ModalCard.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactChild } from "react";

interface ModalCardProps {
  /**
   * Title of this card.
   */
  title: string;
  /**
   * Title of this card.
   */
  children: ReactChild | ReactChild[];
  /**
   * function that triggle when click close sysbol.
   */
  onClose: () => void;
}

export const ModalCard = (props: ModalCardProps) => {
  const { title, children, onClose } = props;
  return (
    <Container>
      <HeaderContainer>
        <Title>{title}</Title>
        <IconWrapper onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "times"]} />
        </IconWrapper>
      </HeaderContainer>
      {children}
    </Container>
  );
};
