import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FilesReducerState {
  files: Array<any>,
}

const initialState: FilesReducerState = {
  files: [],
}

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setFiles: (state, action: PayloadAction<Array<any>>) => {
      state.files = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFiles } = filesSlice.actions;

export default filesSlice.reducer;
