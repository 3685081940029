import axios from 'axios';

const baseURL = process.env.REACT_APP_ORIGIN || window.location.origin;

const axiosStorageAPI = axios.create({
  baseURL: `${baseURL}/api/storage/v1/`,
});

const axiosAuthAPI = axios.create({
  baseURL: `${baseURL}/api/auth/v1/`,
});

export {
  axiosStorageAPI,
  axiosAuthAPI,
};
