import styled from "styled-components";
import { subTitleStyle } from "../GlobalStyle/GlobalStyle.style";
import { maxLayout } from "../../utils/breakpoint/breakpoint";

export const Container = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
`;

export const ChildrenContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: var(--space-base);
  z-index: 1002;
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(33, 45, 55, 0.7);
  z-index: 1001;
`;

export const ButtonContainer = styled.div`
  width: fit-content;
  margin-left: auto;
`;

export const Table = styled.table`
  min-height: 361px;
`;

export const TableRow = styled.tr`
  width: 590px;

  @media ${maxLayout.sm} {
    max-width: 80vw;
  }
`;

export const TableHeader = styled.th`
  ${subTitleStyle}
  color: var(--grey900);
`;

export const TableDescription = styled.td``;
