import { Route, Switch } from "react-router-dom";
import { Router } from "react-router";
import ProtectedRoute from "./middlewares/ProtectedRoute";

import history from "./utils/history/history";
import { BucketList, FileList, PageNotFound } from "./pages";

const RouterClass = () => (
  <Router history={history}>
    <Switch>
      {/** Route */}
      <Route exact path="/" component={BucketList} />
      <Route path="/bucket/:name" component={FileList} />
      {/** Protected Route */}
      <ProtectedRoute exact path="/auth" component={BucketList} />
      <ProtectedRoute exact path="/:teamname" component={BucketList} />
      {/* <ProtectedRoute path="/files" component={FileList} /> */}
      {/** Page not found */}
      <Route component={PageNotFound} />
    </Switch>
  </Router>
);

export default RouterClass;
