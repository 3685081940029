import styled from "styled-components";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";
import { pageHeaderStyle, subTitleStyle } from "../../../components/GlobalStyle/GlobalStyle.style";

export const HeaderWrapper = styled.div`
  position: relative;
  max-width: 1100px;
  width: 100%;
`;

export const ServiceLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 1rem 0 1.5rem;

  @media ${maxLayout.sm} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
`;

export const ServiceLabel = styled.span`
  ${pageHeaderStyle};
  color: ${(props) => props.theme.colors.white};

  @media ${maxLayout.sm} {
    padding-bottom: 6px;
  }
`;

export const ServiceUrl = styled.span`
  ${subTitleStyle};
  font-style: italic;
  margin-left: 20px;

  @media ${maxLayout.sm} {
    margin-left: 0;
  }
`;

export const BackButtonContainer = styled.div`
  position: absolute;
  top: 28px;
  left: -48px;
  cursor: pointer;

  .fa-chevron-left {
    font-size: 1.45em;
  }
`;
