import {IconContainer, TextAndIconContainer, TextContainer} from "./TextAndIcon.style";
import {Link} from "../link/Link";

interface TextWithIconProps {
  /**
   * Text's font size in px
   * @default 14 */
  fontSize?: number,
  /** React Fontawesome element */
  icon: JSX.Element,
  /** If this component can be clicked, enter the destination url here */
  redirectUrl?: string,
  /** Text to be displayed on the right of the icon */
  text: string,
}

export const TextWithIcon = (props: TextWithIconProps) => {
  const { fontSize = 14, icon, redirectUrl, text } = props;

  const getComponent = () => {
    return (
      <TextAndIconContainer>
        <IconContainer>
          {icon}
        </IconContainer>
        <TextContainer fontSize={fontSize}>
          {text}
        </TextContainer>
      </TextAndIconContainer>
    );
  };

  if (redirectUrl) {
    return (
      <Link to={redirectUrl}>
        {getComponent()}
      </Link>
    );
  }

  return getComponent();
};
