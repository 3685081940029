import { ReactChild, useEffect } from "react";
import { Container, ChildrenContainer, Background } from "./Modal.style";

interface ModalProps {
  /**
   * React Component that was render on Modal.
   */
  children: ReactChild;
  /**
   * Display Modal if true
   * @default false
   */
  isOpen: boolean;
  /**
   * function that triggle when click modal background.
   */
  onClose?: () => void;
}

export const Modal = (props: ModalProps) => {
  const { isOpen = false, children, onClose } = props;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <Container isOpen={isOpen}>
      <Background onClick={onClose} />
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};
