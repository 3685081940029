import qs from "qs";
import { axiosStorageAPI } from "../axios";

// Bucket
export const loadBucketList = () => {
  return axiosStorageAPI.get("/buckets");
};

export const loadBucket = (bucketName: string) => {
  return axiosStorageAPI.get(`/buckets/${bucketName}`);
};

export const createBucket = (bucketName: string) => {
  return axiosStorageAPI.put(`/buckets/${bucketName}`);
};

export const deleteBucket = (bucketName: string) => {
  return axiosStorageAPI.delete(`/buckets/${bucketName}`);
};

// Storage
interface LoadObjectsQuery {
  /**
   * Path prefix of objects to list
   * @default /
   */
  prefix: string | null;
  /**
   * Path delimeter of objects to list
   * @default /
   */
  delimeter?: string;
  /**
   * Number of object to list. More objects will be omitted, and can be loaded more with continuationToken.
   * @default 50
   */
  limit?: number;
  /**
   * Path prefix of objects to list
   */
  continuationToken?: string | null;
}

export const loadObjects = (bucketName: string, filter?: LoadObjectsQuery) => {
  if (filter) {
    const query = qs.stringify(filter, {
      addQueryPrefix: true,
      skipNulls: true,
    });
    return axiosStorageAPI.get(`/buckets/${bucketName}/objects${query}`);
  }
  return axiosStorageAPI.get(`/buckets/${bucketName}/objects`);
};

interface FileDetailsQuery {
  /**
   * Path of the file
   * @default /
   */
  path: string | null;
}

export const loadFileDetails = (bucketName: string, filter: FileDetailsQuery) => {
  const query = qs.stringify(filter, {
    addQueryPrefix: true,
    skipNulls: true,
  });
  return axiosStorageAPI.get(`/buckets/${bucketName}/objects/details${query}`);
};

interface CreateObjectsQuery {
  /**
   * The absolute path of a new object to create
   * @default /
   */
  path?: string;
  /**
   * Object type, may be file or folder
   */
  type?: "folder" | "file";
}

export const createObject = (bucketName: string, filter?: CreateObjectsQuery) => {
  if (filter) {
    const query = qs.stringify(filter, {
      addQueryPrefix: true,
      skipNulls: true,
    });
    return axiosStorageAPI.post(`/buckets/${bucketName}/objects${query}`);
  }
  return axiosStorageAPI.post(`/buckets/${bucketName}/objects`);
};

export const deleteObject = (bucketName: string, filter?: CreateObjectsQuery) => {
  if (filter) {
    const query = qs.stringify(filter, {
      addQueryPrefix: true,
      skipNulls: true,
    });
    return axiosStorageAPI.delete(`/buckets/${bucketName}/objects${query}`);
  }
  return axiosStorageAPI.delete(`/buckets/${bucketName}/objects`);
};

// Context
export const getContext = () => {
  return axiosStorageAPI.get("/context");
};

export const activateProduct = () => {
  return axiosStorageAPI.get("/product-activations");
};
