interface LoadingPlaceholderProps {
  width: number | string;
  height: number | string;
  colorStart?: string;
  colorEnd?: string;
  shape?: 'rect' | 'circle';
}

export const LoadingPlaceholder = (props: LoadingPlaceholderProps) => {
  const {
    width,
    height,
    colorStart = '#f8f8f8',
    colorEnd = '#f0f0f0',
    shape = 'rect',
  } = props;

  const shapePropsObject = {
    rect: {
      width,
      height,
    },
    circle: {
      cx: Number(width) / 2,
      cy: Number(height) / 2,
      r: (Number(height) + Number(width)) / 4,
    },
  };

  const svgPropsObject = {
    rect: {
      width,
      height,
    },
    circle: {
      width: width,
      height: height,
    },
  };

  const ShapeElement = shape;
  const shapeProps = shapePropsObject[shape];
  const svgProps = svgPropsObject[shape];

  return (
    <svg {...svgProps}>
      <ShapeElement
        {...shapeProps}
        role='img'
        data-testid="shapeElement"
        fill="url(#linear-gradient)"
      />
      <defs>
        <linearGradient
          id="linear-gradient"
          x1={'0%'}
          y1={'0%'}
          x2={'100%'}
          y2={'0%'}
        >
          <stop offset={'0%'} stopColor={colorStart}>
            <animate
              attributeName="offset"
              values={`-2; -2; 1`}
              dur="2s"
              keyTimes={'0; 0.70; 1'}
              repeatCount="indefinite"
            />
          </stop>
          <stop offset={'50%'} stopColor={colorEnd}>
            <animate
              attributeName="offset"
              values={`0; 0; 2`}
              dur="2s"
              keyTimes={'0; 0.70; 1'}
              repeatCount="indefinite"
            />
          </stop>
          <stop offset={'100%'} stopColor={colorStart}>
            <animate
              attributeName="offset"
              values={`0; 0; 3`}
              dur="2s"
              keyTimes={'0; 0.90; 1'}
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
