import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import theme from "../theme/theme";

export const getFileIcon = (type: string): JSX.Element => {
  let icon = "file";
  let color = theme.colors.grey900;

  if (!type) {
    icon = "file";
  }

  // const extension = filename.substring(filename.lastIndexOf('.') + 1).toLowerCase();

  switch (type) {
    case "folder":
      icon = "folder";
      color = theme.colors.primaryLighten;
      break;
    case "pdf":
      icon += "-pdf";
      color = theme.colors.red;
      break;
    case "doc":
    case "docx":
      icon += "-word";
      color = theme.colors.primaryDarken;
      break;
    case "xls":
    case "xlsx":
    case "csv":
      icon += "-excel";
      color = theme.colors.green;
      break;
    case "ppt":
    case "pptx":
      icon += "-powerpoint";
      color = theme.colors.red;
      break;
    case "jpg":
    case "jpeg":
    case "jfif":
    case "webp":
    case "png":
    case "gif":
    case "svg":
    case "ico":
    case "psd":
    case "ai":
    case "xd":
    case "dwg":
      icon += "-image";
      color = theme.colors.yellow;
      break;
    case "mp4":
    case "m4v":
    case "mov":
    case "mkv":
    case "avi":
    case "wmv":
    case "ts":
    case "m2ts":
    case "webm":
    case "flv":
    case "m3u":
    case "m3u8":
      icon += "-video";
      color = theme.colors.red;
      break;
    case "aac":
    case "ac3":
    case "aif":
    case "aiff":
    case "alac":
    case "dts":
    case "flac":
    case "m4a":
    case "wma":
    case "mp3":
    case "ogg":
    case "opus":
    case "wav":
    case "weba":
      icon += "-audio";
      color = theme.colors.red;
      break;
    case "zip":
    case "rar":
    case "7z":
    case "tar":
    case "gz":
      icon += "-archive";
      color = theme.colors.secondaryLighten;
      break;
    case "html":
    case "css":
    case "scss":
    case "sass":
    case "less":
    case "js":
    case "jsx":
    case "tsx":
    case "asp":
    case "aspx":
    case "php":
    case "vue":
    case "json":
    case "java":
    case "c":
    case "cs":
    case "py":
    case "kt":
    case "swift":
    case "xcode":
    case "mhtml":
      icon += "-code";
      color = theme.colors.secondaryLighten;
      break;
    case "pem":
    case "der":
    case "cer":
      icon += "-certificate";
      break;
    // case 'ttf':
    // case 'otf':
    // case 'woff':
    // case 'woff2':
    // case 'eot':
    //   icon = 'font-case';
    //   break;
    case "pages":
    case "key":
    case "numbers":
    case "indd":
    case "txt":
      icon += "-alt";
      break;
    default:
      icon = "file";
  }

  // @ts-ignore
  return <FontAwesomeIcon color={color} icon={["fas", icon]} />;
};

const whiteSpace = new RegExp(/^[\s]+$/);

export const validateFolderName = (folderName: string): boolean => {
  if (folderName === "" || whiteSpace.test(folderName)) {
    return false;
  } else {
    // Folder name can be Letters, Digits, Underscore(_), Hyphen(-) or Space
    const regex = new RegExp(/^[\s\w-]+$/);
    return regex.test(folderName);
  }
};

export const validateFileName = (fileName: string): boolean => {
  if (fileName === "" || whiteSpace.test(fileName)) {
    return false;
  } else {
    // File name can be Letters, Digits, Underscore(_), Hyphen(-), Dot(.) or Space
    const regex = new RegExp(/^[\s\w.-]+$/);
    return regex.test(fileName);
  }
};

export const trimStartAndEndWhiteSpace = (text: string): string => {
  let trimedText = _.trimStart(text);
  return _.trimEnd(trimedText);
};
