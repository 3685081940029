import { Link, MainTabs, NavBar } from "../../../components";
import {
  BackButtonContainer,
  HeaderWrapper,
  ServiceLabel,
  ServiceLabelContainer,
} from "./FileListHeader.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HeaderContainer } from "../../../components/GlobalStyle/GlobalStyle.style";
import { MainTab } from "../../../components/mainTabs/TabData";
// import { useState, useEffect } from "react";
import { useAppSelector } from "../../../store/hooks";
interface FileListHeaderProps {
  bucketName: string;
}

export const FileListHeader = (props: FileListHeaderProps) => {
  const { bucketName } = props;

  const { user } = useAppSelector((state) => state.user);

  // const [user, setUser] = useState<UserResponse>();
  
  // useEffect(() => {
  //   const response = localStorage.getItem("byteark-user");

  //   if (response) {
  //     const userData = JSON.parse(response);
  //     setUser(userData);
  //   }
  // }, []);

  const tabs: MainTab[] = [
    {
      label: "Files",
      redirectUrl: `/bucket/${bucketName}`,
    },
    {
      label: "Accesses",
      redirectUrl: `/bucket/${bucketName}/access`,
    },
    {
      label: "Usage History",
      redirectUrl: `/bucket/${bucketName}/usage`,
    },
    {
      label: "Settings",
      redirectUrl: `/bucket/${bucketName}/settings`,
    },
  ];

  return (
    <HeaderContainer>
      <NavBar
        user={user ? user.email : "user@inox.co.th"}
        userProfile={user && user.displayImage.sizes.small.url}
        activeTab={"Files"}
        tabs={tabs}
        serviceLabel={"Bucket Name"}
        serviceUrl={"st.bucket-link.com"}
      />
      <HeaderWrapper>
        <BackButtonContainer>
          <Link to={"/"}>
            <FontAwesomeIcon color={"#FFF"} icon={["fas", "chevron-left"]} />
          </Link>
        </BackButtonContainer>
        <ServiceLabelContainer>
          <ServiceLabel>{bucketName}</ServiceLabel>
        </ServiceLabelContainer>
        <MainTabs activeTab={"Files"} tabs={tabs} />
      </HeaderWrapper>
    </HeaderContainer>
  );
};
