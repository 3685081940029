import { TableContainer } from "./BaseTable.style";
import { TableBody, TableRowObject } from "./molecules/TableBody";
import { ColumnProps, TableHeader } from "./molecules/TableHeader";

interface BaseTableProps {
  /** An array of columns' title */
  columns: Array<ColumnProps>,
  /** An array of of table's rows */
  rows: Array<TableRowObject>,
}

export const BaseTable = (props: BaseTableProps) => {
  const { columns, rows } = props;

  if (columns.length === 0 || rows.length === 0) {
    return (<></>);
  }

  return (
    <TableContainer>
      <TableHeader
        columns={columns}
        roundedBorder={false} />
      <TableBody
        columns={columns}
        rows={rows} />
    </TableContainer>
  );
};
