import styled, { css } from "styled-components";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";

interface TabStyleProps {
  isActive: boolean,
}

export const TabContainer = styled.div<TabStyleProps>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;

  ${(props) => props.isActive && css`
    :after {
      content: '';
      position: absolute;
      display: block;
      height: 2.5px;
      width: 100%;
      bottom: 0;
      background-color: var(--primary);
    }
  `};
`;

export const Text = styled.p<TabStyleProps>`
  color: ${(props) => props.isActive ? 'var(--primary)' : 'var(--grey900)'};
  font-weight: 500;
  font-size: 18px;
  white-space: pre;

  @media ${maxLayout.sm} {
    font-size: 16px;
  }
`;

export const Badge = styled.div<TabStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.isActive ? 'var(--primary)' : 'var(--grey900)'};
  border-radius: 50px;
  color: var(--white);
  font-size: 12px;
  width: 22px;
  height: 22px;
  margin-left: var(--space-s);
  line-height: 0;
`;
