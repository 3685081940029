import { css } from "styled-components";

const Colors = css`
    --primary: #00a6ed;
    --secondary: #5613b5;
    --secondary2: #6957c5;

    --middle: #2d83db;
    --primaryLighten: #62bff4;
    --secondaryLighten: #735de5;
    --primaryDarken: #006692;
    --secondaryDarken: #443880;
    --primaryOverlay: rgba(0, 166, 237, 0.1);

    --grey100: #fbfbfc;
    --grey200: #f8f9fa;
    --grey300: #eef0f2;
    --grey400: #e8eaee;
    --grey500: #d0d4d8;
    --grey600: #b1b9c0;
    --grey700: #9ea4a9;
    --grey800: #6c757d;
    --grey900: #343a40;

    --white: #ffffff;
    --overlay: #253742;
    --overlayHover: #465562;

    --navy: #212d37;
    --navyDarken: #1a242c;
    --lightBlue: #f0f5f8;

    --red: #eb5757;
    --green: #3fb772;
    --yellow: #f0b542;
`;

export default Colors;
