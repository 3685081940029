import { NavBar } from "../../../components";
import {
  HeaderWrapper,
  ServiceLabel,
  ServiceLabelContainer,
} from "./BucketListHeader.style";
import { HeaderContainer } from "../../../components/GlobalStyle/GlobalStyle.style";
// import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";

export const BucketListHeader = () => {
  const { user } = useAppSelector((state) => state.user);
  // const [user, setUser] = useState<UserResponse>();

  // useEffect(() => {
  //   const response = localStorage.getItem("byteark-user");

  //   if (response) {
  //     const userData = JSON.parse(response);
  //     setUser(userData);
  //   }
  // }, []);

  return (
    <HeaderContainer>
      {user ? (
        <NavBar
          user={user.email}
          userProfile={user.displayImage.sizes.small.url}
          serviceLabel={"Buckets"}
        />
      ) : (
        <NavBar user="user@inox.co.th" serviceLabel={"Buckets"} />
      )}
      <HeaderWrapper>
        <ServiceLabelContainer>
          <ServiceLabel>Buckets</ServiceLabel>
        </ServiceLabelContainer>
      </HeaderWrapper>
    </HeaderContainer>
  );
};
