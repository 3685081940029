import styled, { css } from "styled-components";
import { maxLayout } from "../../utils/breakpoint/breakpoint";
import {
  sectionHeaderStyle,
  subTitleStyle,
} from "../GlobalStyle/GlobalStyle.style";

export const Container = styled.div`
  cursor: pointer;
  position: fixed;
  left: var(--space-l);
  bottom: var(--space-xl);
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  padding: var(--space-base);
  width: 428px;
  border: 1px solid var(--grey400);
  transition: background-color 250ms ease;

  :hover {
    background-color: var(--grey100);
  }

  @media ${maxLayout.md} {
    min-width: unset;
    max-width: 80vw;
  }
`;

export const IconWrapper = styled.div<{
  iconColor: "success" | "warning" | "danger";
}>`
  color: var(--green);
  margin-right: var(--space-base);

  ${(props) => {
    switch (props.iconColor) {
      case "success":
        return css`
          color: var(--green);
        `;
      case "warning":
        return css`
          color: var(--yellow);
        `;
      case "danger":
        return css`
          color: var(--red);
        `;
      default:
        return css`
          color: var(--green);
        `;
    }
  }}
`;

export const ContentContainer = styled.div`
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  ${sectionHeaderStyle}
  color: var(--grey900);
`;

export const Desciption = styled.div`
  ${subTitleStyle}
  color: var(--grey700);
  margin-top: var(--space-xs);
`;
