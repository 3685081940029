import { Button, Modal, ModalCard, TextInput } from "../../../components";
import { InputWrapper } from "../../../components/modal/createBucket/CreateBucketModal.style";
import { trimStartAndEndWhiteSpace, validateFolderName } from "../../../utils/file";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { createObject } from "../../../services/apis";

interface NewFolderModalProps {
  bucketName: string,
  currentPath: string,
  isCreatingFolder: boolean,
  onCancel: () => void,
  onSuccess: (folderName: string) => void,
}

export const NewFolderModal = (props: NewFolderModalProps) => {
  const {
    bucketName,
    currentPath,
    isCreatingFolder,
    onCancel,
    onSuccess,
  } = props;

  const [folderName, setFolderName] = useState<string>("");
  const [createResultMessage, setCreateResultMessage] = useState<string>("");

  const handleFolderNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFolderName(event.target.value);
  };

  const handleCreateFolder = async () => {
    let trimedFolderName = trimStartAndEndWhiteSpace(folderName);

    try {
      const response = await createObject(bucketName, {
        path: `${currentPath}${trimedFolderName}`,
        type: "folder",
      });

      if (response.status === 201) {
        onSuccess(trimedFolderName);
        setFolderName("");
      }
    } catch (err: any) {
      const data: { errorCode: string; errorMessage: string } =
        err.response.data;

      // Just split cases
      // TODO: Error message handle
      switch (data.errorCode) {
        case "authenticationRequired":
          setCreateResultMessage(data.errorMessage);
          return;
        case "forbidden":
          setCreateResultMessage(data.errorMessage);
          return;
        case "resourceNotFound":
          setCreateResultMessage(data.errorMessage);
          return;
        case "internalServerError":
          setCreateResultMessage(data.errorMessage);
          return;
        default:
          return;
      }
    }
  };

  const handleCreateFolderKeyDown = (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && validateFolderName(folderName)) {
      handleCreateFolder();
    } else if (event.key === "Escape") {
      onCancel();
    }
  };

  return (
    <Modal
      isOpen={isCreatingFolder}
      onClose={onCancel}
    >
      <ModalCard
        title="New folder"
        onClose={onCancel}
      >
        <InputWrapper>
          <TextInput
            text={folderName}
            error={
              !["Success", ""].includes(createResultMessage) ||
              (!validateFolderName(folderName) && folderName !== "")
            }
            placeHolder="Enter folder name"
            label={
              !["Success", ""].includes(createResultMessage)
                ? createResultMessage
                : validateFolderName(folderName) || folderName === ""
                  ? `Folder name can be letters, digits, underscore(_), hyphen(-) or space`
                  : `Invalid name format. Folder name can be letters, digits, underscore(_), hyphen(-) or space`
            }
            onChange={handleFolderNameChange}
            onKeyDown={handleCreateFolderKeyDown}
          />
        </InputWrapper>
        <div style={{ marginLeft: "auto", width: "fit-content" }}>
          <Button
            onClick={handleCreateFolder}
            disable={!validateFolderName(folderName)}
          >
            Create
          </Button>
        </div>
      </ModalCard>
    </Modal>
  );
};
