import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Input = styled.input`
  color: var(--grey900);
  background-color: var(--white);
  border: 1px solid var(--grey400);
  padding-top: var(--space-s);
  padding-right: var(--space-l);
  padding-bottom: var(--space-s);
  padding-left: 9px;
  font-size: 14px;
  line-height: 1.4;
  width: 100%;
  border-radius: var(--border-radius);
  transition: border-color 0.2s ease-out;

  ::placeholder {
    color: var(--grey500);
  }

  ::-webkit-search-cancel-button {
    display: none;
  }

  &:focus,
  &:focus-visible {
    border-color: rgba(0, 166, 237, 0.5);
    outline: none;
  }

  &:disabled {
    background-color: var(--grey400);
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  color: var(--grey700);
  top: 50%;
  right: 10px;
  transform: translateY(calc(-50% - 1px));
`;
