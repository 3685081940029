import _ from "lodash";
import { useState, useEffect, ChangeEvent, KeyboardEvent } from "react";
import { useLocation } from "react-router-dom";

import { Container } from "../../components/GlobalStyle/GlobalStyle.style";
import {
  PageContainer,
  Content,
  SearchBoxWrapper,
  ActionContainer,
  SearchResultContainer,
} from "./BucketList.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs } from "../../components/tabs/Tabs";
import { loadBucketList, createBucket } from "../../services/apis";
import { TabProps } from "../../components/tabs/molecules/Tab";
import { EmptyMessage } from "../../components/messages/EmptyMessage";
import { LoadingMessage } from "../../components/messages/LoadingMessage";
import { ErrorMessage } from "../../components/messages/ErrorMessage";
import { NoResultMessage } from "../../components/messages/NoResultMessage";

import { SearchBox } from "../../components/searchBox/SearchBox";
import { Button, CreateBucketModal } from "../../components";
import { validateBucketName } from "../../utils/bucket";

import { BucketListTable } from "./organisms/BucketListTable";
import { BucketListHeader } from "./organisms/BucketListHeader";

export interface BucketListData {
  name: string;
  domainName: string;
}

export const BucketList = () => {
  const [data, setData] = useState<Array<BucketListData> | null>();
  const [filterData, setFilterData] = useState<Array<BucketListData> | null>();
  const [tabItems, setTabItems] = useState<Array<TabProps>>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  // Modal State for creating bucket.
  const [isCreatingBucket, setIsCreatingBucket] = useState<boolean>(false);
  const [bucketName, setBucketName] = useState<string>("");
  const [createdBucketName, setCreatedBucketName] = useState<string>("");
  const [createResultMessage, setCreateResultMessage] = useState<string>("");

  const handleBucketNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBucketName(event.target.value);
  };

  const handleCreateBucket = async () => {
    try {
      const response = await createBucket(bucketName);

      if (response.status === 201) {
        setCreateResultMessage("Success");
        setIsCreatingBucket(false);
        setCreatedBucketName(bucketName);
        setBucketName("");
        setTimeout(() => {
          setCreateResultMessage("");
        }, 5000);
      }
    } catch (err: any) {
      const data = _.get(err, "response.data");

      // Just split cases
      // TODO: Error message handle
      switch (data.errorCode) {
        case "authenticationRequired":
          setCreateResultMessage(data.errorMessage);
          return;
        case "forbidden":
          setCreateResultMessage(data.errorMessage);
          return;
        case "resourceAlreadyExists":
          setCreateResultMessage(data.errorMessage);
          return;
        case "internalServerError":
          setCreateResultMessage(data.errorMessage);
          return;
        default:
          return;
      }
    }
  };

  const handleCreateBucketKeyDown = (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && validateBucketName(bucketName)) {
      handleCreateBucket();
    } else if (event.key === "Escape") {
      setIsCreatingBucket(false);
      setBucketName("");
      setCreateResultMessage("");
    }
  };

  // Searching State
  const [searchValue, setSearchValue] = useState<string>("");

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    if (event.target.value === "") {
      setFilterData(data);
    } else {
      const filterData = _.filter(data, (item) => {
        return item.name
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      setFilterData(filterData);
    }
  };

  const { search } = useLocation();
  const tabName = new URLSearchParams(search).get("tab");

  useEffect(() => {
    const loadData = async () => {
      setData(null);
      setFilterData(null);
      setIsLoading(true);
      setError(null);

      try {
        const response = await loadBucketList();
        let data = _.get(response, "data");
        const totalBuckets = data.data.length;

        // Waiting for an API, temporary return an empty array.
        if (tabName === "everyone") {
          data = { data: [] };
        }

        setData(data.data);
        setFilterData(data.data);
        setIsLoading(false);
        setError(null);

        const tabItems = [
          {
            count: totalBuckets,
            isActive: !tabName || tabName === "mine",
            redirectUrl: "/?tab=mine",
            text: "My Buckets",
          },
          {
            count: 0,
            isActive: tabName === "everyone",
            redirectUrl: "/?tab=everyone",
            text: "Everyone's Buckets",
          },
        ];
        setTabItems(tabItems);
      } catch (error) {
        setIsLoading(false);
        setError(error);
      }
    };

    loadData();
    document.title = `ByteArk Storage`;
  }, [tabName]);

  const isEmpty = !error && data && !Boolean(data.length);
  const isNotFound =
    Boolean(searchValue.length) &&
    !error &&
    filterData &&
    !Boolean(filterData.length);

  return (
    <PageContainer>
      <BucketListHeader />
      <Container>
        <Content>
          {tabItems && <Tabs items={tabItems} />}
          <ActionContainer>
            <SearchBoxWrapper>
              <SearchBox
                text={searchValue}
                placeholder="Search by name..."
                onChange={handleSearchChange}
                onDelete={() => {
                  setSearchValue("");
                  setFilterData(data);
                }}
              />
            </SearchBoxWrapper>
            <Button isIconButton onClick={() => setIsCreatingBucket(true)}>
              <FontAwesomeIcon icon={["fas", "plus"]} />
              New Bucket
            </Button>
          </ActionContainer>
          {filterData && searchValue !== "" && (
            <SearchResultContainer>
              {filterData.length !== 0 ? filterData.length : "No"} results for ‘
              {searchValue}’
            </SearchResultContainer>
          )}
          {Boolean(isLoading) && <LoadingMessage />}
          {!Boolean(isLoading) && !isEmpty && filterData && (
            <BucketListTable filterData={filterData} />
          )}
          {!Boolean(isLoading) && error && (
            <ErrorMessage errorMessage={error.message} />
          )}
          {!Boolean(isLoading) && isEmpty && (
            <EmptyMessage
              description={"Your team doesn't have any buckets."}
              item={"buckets"}
            />
          )}
          {!Boolean(isLoading) && isNotFound && (
            <NoResultMessage item={searchValue} />
          )}
        </Content>
      </Container>
      <CreateBucketModal
        bucketName={bucketName}
        isOpen={isCreatingBucket}
        createResultMessage={createResultMessage}
        createdBucketName={createdBucketName}
        onClose={() => {
          setIsCreatingBucket(false);
          setBucketName("");
          setCreateResultMessage("");
        }}
        onChange={handleBucketNameChange}
        onKeyDown={handleCreateBucketKeyDown}
        onCreate={handleCreateBucket}
        onCreateSuccess={() => setCreateResultMessage("")}
      />
    </PageContainer>
  );
};
