import styled from "styled-components";
import {maxLayout} from "../../../utils/breakpoint/breakpoint";
import {pageHeaderStyle} from "../../../components/GlobalStyle/GlobalStyle.style";

export const HeaderWrapper = styled.div`
  max-width: 1100px;
  width: 100%;
`;

export const ServiceLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: var(--space-base) 0 var(--space-m);

  @media ${maxLayout.sm} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: var(--space-base);
  }
`;

export const ServiceLabel = styled.span`
  ${pageHeaderStyle};

  @media ${maxLayout.sm} {
    font-size: 20px;
    padding-bottom: 6px;
  }
`;
