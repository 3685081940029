import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TextContainer,
  TitleText,
  SubText,
  DescriptionText,
  EmptyContainer,
  PageContainer,
} from "./PageNotFound.style";

export const PageNotFound = () => {
  return (
    <>
      <EmptyContainer />
      <PageContainer>
        <TextContainer>
          <TitleText>
            4 <FontAwesomeIcon icon={["fad", "do-not-enter"]} size="sm" /> 4
          </TitleText>
          <SubText>Page Not Found</SubText>
          <DescriptionText>
            Sorry, the page you're looking for is unavailable.
          </DescriptionText>
        </TextContainer>
      </PageContainer>
    </>
  );
};
