import { useEffect, useState } from "react";
import { BaseTable } from "../../../components/baseTable/BaseTable";
import { BucketListData } from "../BucketList";
import { TableRowObject } from "../../../components/baseTable/molecules/TableBody";
import {TextWithIcon} from "../../../components/textWithIcon/TextWithIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DomainText, TableCellContainer } from "./BucketListTable.style";

interface BucketListTableProps {
  filterData: Array<BucketListData>,
}

export const BucketListTable = (props: BucketListTableProps) => {
  const { filterData } = props;
  const [tableRows, setTableRows] = useState<Array<TableRowObject>>();
  const columns = [
    {
      title: "Name",
    },
  ];

  useEffect(() => {
    const rows = filterData?.map((d: BucketListData) => ({
      redirectUrl: `/bucket/${d.name}`,
      columns: [
        <TableCellContainer>
          <TextWithIcon
            fontSize={16}
            icon={<FontAwesomeIcon color={"#B1B9C0"} icon={["fas", "cloud"]} />}
            text={d.name}
          />
          <DomainText>
            { d.domainName }
          </DomainText>
        </TableCellContainer>
      ],
    }));
    setTableRows(rows);
  }, [filterData]);

  if (!tableRows) {
    return (<></>);
  }

  return (
    <BaseTable
      columns={columns}
      rows={tableRows} />
  );
};
