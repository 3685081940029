import { memo, ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { config, library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";

import theme from "../../theme/theme";
import BaseStyle from "./GlobalStyle.style";
import "../../index.css";

config.autoAddCss = false;
library.add(fas, far, fal, fad);

interface GlobalStyleProps {
  /**
   * Children component
   */
  children: ReactNode;
}

/**
 * Since this base style doesn't change,
 * we'll let it memo nothing so it will render only once,
 * and not facing the [font-reloading issue].
 */
const BaseStyleThatRenderOnlyOnce = memo(() => <BaseStyle />);

const GlobalStyle = (props: GlobalStyleProps) => {
  const { children } = props;

  return (
    <>
      <BaseStyleThatRenderOnlyOnce />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  );
};

export default GlobalStyle;
