import { axiosStorageAPI, axiosAuthAPI } from ".";

const baseURL = process.env.REACT_APP_ORIGIN || window.location.origin;

const configAxiosForWebConsole = (teamName: string) => {
  // setup axios for app api
  axiosStorageAPI.defaults.withCredentials = true;
  axiosStorageAPI.defaults.headers.common = { "X-ByteArk-Team": teamName };

  axiosStorageAPI.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        window.location.href = `${baseURL}/accounts/byteark`;
      }
      return Promise.reject(error);
    }
  );

  // setup axios for auth service api
  axiosAuthAPI.defaults.withCredentials = true;
  axiosAuthAPI.defaults.headers.common = { "X-ByteArk-Team": teamName };

  axiosAuthAPI.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        window.location.href = `${baseURL}/accounts/byteark`;
      }

      return Promise.reject(error);
    }
  );
};

export { configAxiosForWebConsole };
