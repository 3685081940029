import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Sizes = {
  height: number;
  width: number;
  url: string;
};

type ImageResponse = {
  sizes: {
    default: Sizes;
    large: Sizes;
    medium: Sizes;
    small: Sizes;
  };
};

export type UserResponse = {
  displayImage: ImageResponse;
  email: string;
  id: number;
  lang: "th" | "en";
  name: string;
  role: string;
  roleNames: string[];
  teamId: number;
};

interface UserReducerState {
  user?: UserResponse;
}

const initialState: UserReducerState = {
  user: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserResponse>) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
