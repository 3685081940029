import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {CopyButton, InputCopyContainer, Tooltip, TooltipText} from "./InputCopy.style";
import { Input } from "../textInput/TextInput.style";

interface InputCopyProps {
  /**
   * Value to display and to copy.
   */
  value: string;
}

export const InputCopy = (props: InputCopyProps) => {
  const { value } = props;
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const onCopy = () => {
    const hiddenElement = document.createElement('input');

    document.body.appendChild(hiddenElement);
    hiddenElement.value = value;
    hiddenElement.select();
    document.execCommand('copy');
    document.body.removeChild(hiddenElement);
    setIsCopied(true);

    const copyStateTimeout = setTimeout(() => {
      setIsCopied(false);
      clearTimeout(copyStateTimeout);
    }, 1200);
  };

  return (
    <InputCopyContainer>
      <Input
        value={value}
        type="text"
        readOnly={true}
      />
      <Tooltip>
        <TooltipText>
          { isCopied ? "Copied!" : "Copy" }
        </TooltipText>
        <CopyButton
          onClick={onCopy}>
          {
            Boolean(isCopied) ? (
              <FontAwesomeIcon icon={['fas', 'check']} />
            ) : (
              <FontAwesomeIcon icon={['fas', 'copy']} />
            )
          }
        </CopyButton>
      </Tooltip>
    </InputCopyContainer>
  );
};
