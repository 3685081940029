import { css } from "styled-components";

const Spaces = css`
    --space-xl: 48px;
    --space-l: 32px;
    --space-m: 24px;
    --space-base: 16px;
    --space-s: 8px;
    --space-xs: 4px;
`;

export default Spaces;
