import styled from "styled-components";

export const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: var(--space-m);

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  
  a {
    margin-right: var(--space-l);

    :last-child {
      margin-right: var(--space-s);
    }
  }
`;
