import styled from "styled-components";
import { Input } from "../textInput/TextInput.style";

export const InputCopyContainer = styled.div`
  position: relative;

  ${Input} {
    padding-right: 40px;
  }
`;

export const CopyButton = styled.button`
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  color: var(--grey700);

  height: 100%;
  width: 40px;
`;

export const TooltipText = styled.div`
  visibility: hidden;
  width: 120px;
  background-color: var(--grey800);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 38px;
  left: 50%;
  margin-left: -60px;
  text-transform: capitalize;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);

  :after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--grey800) transparent transparent transparent;
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  height: 100%;
  width: 40px;

  :hover {
    ${TooltipText} {
      visibility: visible;
    }
  }
`;
