import { BaseErrorMessage } from "../baseErrorMessage/BaseErrorMessage";

interface EmptyMessageProps {
  /**
   * The name of the item that is empty
   * @default 'data'
   * */
  item?: string,
  /**
   * Description
   * @default `You don't have any ${item}.`
   * */
  description?: string,
}

export const EmptyMessage = (props: EmptyMessageProps) => {
  let { description, item } = props;

  if (!item) {
    item = 'data';
  }

  if (!description) {
    description = `You don't have any ${item}.`;
  }

  return (
    <BaseErrorMessage
      icon={'inbox'}
      title={`No ${item}`}
      description={description}
    />
  );
};
