import {
  Container,
  IconWrapper,
  ContentContainer,
  TitleContainer,
  Title,
  Desciption,
} from "./Toast.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-common-types";

interface ToastProps {
  /**
   * Title of this toast
   */
  title: string;
  /**
   * Description of this toast
   */
  description?: string;
  /**
   * Icon color
   * @default success
   */
  iconColor?: "success" | "warning" | "danger";
  /**
   * Icon color
   * @default check-circle
   */
  icon?: IconName;
  /**
   * Function that triggle while click this toast.
   */
  onClick?: () => void;
}

export const Toast = (props: ToastProps) => {
  const {
    title,
    description,
    iconColor = "success",
    icon = "check-circle",
    onClick,
  } = props;
  return (
    <Container onClick={onClick}>
      <IconWrapper iconColor={iconColor}>
        <FontAwesomeIcon size={"2x"} icon={["far", icon]} />
      </IconWrapper>
      <ContentContainer>
        <TitleContainer>
          <Title>{title}</Title>
          <FontAwesomeIcon
            size={"1x"}
            color={"#6c757d"}
            icon={["fas", "times"]}
          />
        </TitleContainer>
        <Desciption>{description}</Desciption>
      </ContentContainer>
    </Container>
  );
};
