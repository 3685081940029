import styled, { css } from "styled-components";

export const Container = styled.div`
  margin-bottom: var(--space-base);
`;

export const Input = styled.input<{ error?: boolean }>`
  color: var(--grey900);
  background-color: var(--white);
  border: 1px solid var(--grey400);
  padding-top: var(--space-s);
  padding-right: var(--space-l);
  padding-bottom: var(--space-s);
  padding-left: 9px;
  font-size: 14px;
  line-height: 1.4;
  width: 100%;
  border-radius: var(--border-radius);
  transition: border-color 0.2s ease-out;

  ::placeholder {
    color: var(--grey500);
  }

  &:focus,
  &:focus-visible {
    border-color: rgba(0, 166, 237, 0.5);
    outline: none;
  }

  &:disabled {
    background-color: var(--grey400);
  }

  ${(props) =>
    props.error &&
    css`
      border-color: var(--red) !important;
    `}
`;

export const Label = styled.p<{ error?: boolean }>`
  color: var(--grey700);
  font-size: 12px;
  margin-top: var(--space-xs);

  ${(props) =>
    props.error &&
    css`
      color: var(--red);
    `}
`;
