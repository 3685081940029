import {
  add,
  isToday as dateIsToday,
  startOfDay,
  format,
  getDay,
  getDate,
  getMonth,
  getYear,
  isSameDay,
  isAfter,
  isWithinInterval,
  parseISO,
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';
import { th } from 'date-fns/locale';

const formatDate = (date : string) => {
  const newDate = new Date(date);
  const year = newDate.getFullYear() + 543;
  const shortYear = year.toString().substring(2, 4);
  const dateMonthString = format(newDate, 'd MMM', { locale: th });
  return `${dateMonthString} ${shortYear}`;
};

const formatThaiFullDate = (date : string) => {
  const dateObject = new Date(date);
  const day = dateObject.getDate();
  const month = getMonthText(dateObject.getMonth() + 1);
  const year = dateObject.getFullYear() + 543;
  return `${day} ${month} ${year}`;
}

const formatDateTime = (date : string) => {
  const newDate = new Date(date);
  return format(newDate, 'dd/MM/yyyy HH:mm:ss', { locale: th });
};

const transformSecondToMinute = (seconds : number) => {
  return new Date(seconds * 1000).toISOString().substr(14, 5)
}

/**
 * ATTENTION:
 * Please do not convert dateTime by yourself. Pass dateTime as-is from an API, and let this util do its stuffs.
 */

/**
 * To get date, month, and year of today. Returns as an array by default.
 * @param {boolean} asArray   If true then returns an array of day, month, year. If false then returns a formatted string.
 */
const getToday = (asArray = true) => {
  const now = new Date();
  if (asArray) {
    return [getDate(now), getMonth(now)+1, getYear(now)];
  } else {
    return format(now, 'yyyy/MM/dd');
  }
}

/**
 * Return true if the dateString is today.
 * @param {string} dateString   in ISO format.
 */
const isToday = (dateString : string) => {
  return dateIsToday(new Date(dateString));
}

/**
 * Return true if dateString is in the past.
 * @param {string} dateString   in ISO format.
 */
 const isAfterTime = (dateString : string) => {
   const now = new Date();
  return isAfter(now, new Date(dateString));
}

/**
 * To get day in long text
 * @param {string} dateString   If true then returns year in Buddhist format. If false then returns year in Before Christ format.
 * @param {boolean} buddhistFormat   If true then returns year in Buddhist format. If false then returns year in Before Christ format.
 */
const getDayLongText = (dateString = new Date(), buddhistFormat = true) => {
  const now = new Date(dateString);
  const day = getDayText(getDay(now));
  const date = getDate(now);
  const month = getMonthText(getMonth(now) + 1);
  let yearPrefix = 'ค.ศ.';
  let year = getYear(now);
  if (buddhistFormat) {
    yearPrefix = 'พ.ศ.';
    year += 543;
  }
  return `วัน${day}ที่ ${date} ${month} ${yearPrefix} ${year}`;
}

/**
 * To get a formatted hours and minutes in HH:mm
 * @param {string} date.
 */
const formatHourMinute = (date : string) => {
  const parsedTime = new Date(date);
  return format(parsedTime, 'HH:mm');
}

/**
 * To get a name of the given day number in week.
 * @param {number} dayNumber   1-7; 1 is Monday; 7 is Sunday;
 */
const getDayText = (dayNumber : number) => {
  switch (dayNumber) {
    case 0:
      return 'อาทิตย์';
    case 1:
      return 'จันทร์';
    case 2:
      return 'อังคาร';
    case 3:
      return 'พุธ';
    case 4:
      return 'พฤหัส';
    case 5:
      return 'ศุกร์';
    case 6:
      return 'เสาร์';
    default:
      return 'จันทร์';
  }
};


const getDayShortText = (dayNumber : number) => {
  switch (dayNumber) {
    case 0:
      return 'อา.';
    case 1:
      return 'จ.';
    case 2:
      return 'อ.';
    case 3:
      return 'พ.';
    case 4:
      return 'พฤ.';
    case 5:
      return 'ศ.';
    case 6:
      return 'ส.';
    default:
      return 'จ.';
  }
};


/**
 * To get a name of the given month number in year.
 * @param {number} monthNumber   1-12; 1 is January; 12 is December; YOU ARE REMINDED THAT THIS IS NOT AN INDEX. DO NOT PASS 0 TO ME!
 */
const getMonthText = (monthNumber : number) => {
  switch (monthNumber) {
    case 1:
      return 'มกราคม';
    case 2:
      return 'กุมภาพันธ์';
    case 3:
      return 'มีนาคม';
    case 4:
      return 'เมษายน';
    case 5:
      return 'พฤษภาคม';
    case 6:
      return 'มิถุนายน';
    case 7:
      return 'กรกฎาคม';
    case 8:
      return 'สิงหาคม';
    case 9:
      return 'กันยายน';
    case 10:
      return 'ตุลาคม';
    case 11:
      return 'พฤศจิกายน';
    case 12:
      return 'ธันวาคม';
    default:
      return 'มกราคม';
  }
};

const getMonthShortText = (monthNumber : number) => {
  switch (monthNumber) {
    case 0:
      return 'ม.ค.';
    case 1:
      return 'ก.พ.';
    case 2:
      return 'มี.ค.';
    case 3:
      return 'เม.ย.';
    case 4:
      return 'พ.ค.';
    case 5:
      return 'มิ.ย.';
    case 6:
      return 'ก.ค.';
    case 7:
      return 'ส.ค.';
    case 8:
      return 'ก.ย.';
    case 9:
      return 'ต.ค.';
    case 10:
      return 'พ.ย.';
    case 11:
      return 'ธ.ค.';
    default:
      return 'ม.ค.';
  }
};


const getDurationLongFormat = (startTime : string, endTime : string) => {
  const diffTime = differenceInSeconds(parseISO(endTime), parseISO(startTime));
  if (!diffTime) {
    return '00:00';
  }

  let duration = '';
  const minutes = Math.abs(Math.floor(diffTime / 60) % 60);
  const hours = Math.abs(Math.floor(diffTime / 60 / 60));

  if (hours) {
    duration += `${hours} ชั่วโมง`;
  }

  if (minutes) {
    if (duration) {
      duration += ' '
    }
    duration += `${minutes} นาที`;
  }

  // return `${hours.length < 2 ? 0 + hours : hours}:${minutes.length < 2 ? 0 + minutes : minutes}`;
  return duration;
}

const getDayRemaining = (startTime : string, limitOfDay = 90) => {
  const expiredDate = add(startOfDay(new Date(startTime)), {
    days: limitOfDay,
  });
  return differenceInDays(startOfDay(expiredDate), startOfDay(new Date()));
}

/**
 * To check if startTime and endTime have already passed (The time right now is newer than them) Returns true the given time is older than now.
 * @param {string} startTime  Start time of a program in ISO format.
 * @param {string} endTime    End time of a program in ISO format.
 */
const isPassed = (startTime : string, endTime : string) => {
  const now = new Date();
  return isAfter(now, parseISO(startTime)) && isAfter(now, parseISO(endTime));
};

/**
 * Returns true if the time right now is inside the startTime and the endTime.
 * @param {string} startTime  Start time of a program in ISO format.
 * @param {string} endTime    End time of a program in ISO format.
 */
const isInCurrentPeriod = (startTime : string, endTime : string) => {
  const now = new Date();
  return isWithinInterval(now, { start: parseISO(startTime), end: parseISO(endTime) });
};

const getDistanceFromNow = (isoString : string) => {
  const now = new Date();
  return Math.abs(differenceInDays(now, parseISO(isoString)));
}

const getDistanceFromNowInWords = (date : string) => {
  const now = new Date();
  const parsedTime = new Date(date);
  const minutes = differenceInMinutes(now, parsedTime);
  const hours = differenceInHours(now, parsedTime);
  const days = differenceInDays(now, parsedTime);
  const weeks = differenceInWeeks(now, parsedTime);
  const months = differenceInMonths(now, parsedTime);
  const yaers = differenceInYears(now, parsedTime);
  if (minutes < 1) {
    return "น้อยกว่า 1 นาที";
  } else if (minutes < 60) {
    return `${minutes} นาทีที่แล้ว`;
  } else if (hours < 24) {
    return `${hours} ชั่วโมงที่แล้ว`;
  } else if (days < 7) {
    return days === 1 ? "เมื่อวานนี้" : `${days} วันที่แล้ว`;
  } else if (weeks < 4) {
    return weeks === 1 ? "สัปดาห์ที่แล้ว" : `${weeks} สัปดาห์ที่แล้ว`;
  } else if (months < 12) {
    return months === 1 ? "เดือนที่แล้ว" : `${months} เดือนที่แล้ว`;
  } else if (yaers < 12) {
    return yaers === 1 ? "ปีที่แล้ว" : `${yaers} ปีที่แล้ว`;
  }
}

export {
  formatDate,
  formatThaiFullDate,
  formatDateTime,
  formatHourMinute,
  transformSecondToMinute,
  isToday,
  isAfterTime,
  getToday,
  getDayLongText,
  getDayText,
  getDayShortText,
  getDistanceFromNow,
  getMonthText,
  getMonthShortText,
  getDurationLongFormat,
  getDayRemaining,
  isInCurrentPeriod,
  isPassed,
  isSameDay,
  differenceInDays,
  getDistanceFromNowInWords
};
