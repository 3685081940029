import _ from "lodash";
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import theme from "../../theme/theme";
import { BreadcrumbContainer, Path, PathSpan } from "./Breadcrumb.style";

interface BreadcrumbProps {
  baseUrl: string,
  path: string,
}

export const Breadcrumb = (props: BreadcrumbProps) => {
  const { baseUrl, path } = props;
  const decodedPath = decodeURIComponent(path);
  let pathArray = decodedPath.split('/');
  pathArray = _.without(pathArray, '');

  const activeColor = theme.colors.grey900;
  const normalColor = theme.colors.grey700;

  const getLink = (index: number): string => {
    let link = `/${baseUrl}?path=`;
    pathArray.forEach((path, i) => {
      if (i < index) {
        link += `${path}%2F`;
      }

      if (index === i) {
        link += `${path}%2F`;
        return link;
      }
    });

    return link;
  }

  return (
    <BreadcrumbContainer>
      <Path isActive={pathArray.length === 0}>
        <Link to={`/${baseUrl}`}>
          <FontAwesomeIcon
            color={pathArray.length === 0 ? activeColor : normalColor}
            icon={["fas", "folder-open"]} />
        </Link>
        {
          Boolean(pathArray.length > 0) && (
            <FontAwesomeIcon
              color={normalColor}
              icon={["far", "chevron-right"]} />
          )
        }
      </Path>
      { pathArray.map((path, index) => (
        <Path
          isActive={_.last(pathArray) === path}
          key={`path${index}`}>
          <Link to={getLink(index)}>
            <PathSpan
              isActive={_.last(pathArray) === path}>
              { path }
            </PathSpan>
          </Link>
          { index < pathArray.length - 1  && (
            <FontAwesomeIcon
              color={normalColor}
              icon={["far", "chevron-right"]} />
          )}
        </Path>
      ))}
    </BreadcrumbContainer>
  );
};
