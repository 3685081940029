import styled, { css } from "styled-components";
import { maxLayout } from "../../utils/breakpoint/breakpoint";

export const TableContainer = styled.div`
  background-color: var(--white);
  border-spacing: 0;
  width: 100%;
`;

export const TableCellStyles = css`
  padding-left: var(--space-base);
  padding-right: var(--space-base);
  font-size: 14px;
  text-align: left;
  color: var(--grey900);

  &.column-lg {
    width: 100%;
  }

  &.column-md {
    max-width: 50%;
  }

  &.column-sm {
    max-width: 25%;
  }

  &.column-xs {
    max-width: 100px;
  }

  @media ${maxLayout.sm} {
    padding-left: var(--space-s);
    padding-right: var(--space-s);
  }
`;
