import { css } from "styled-components";

import Colors from "./Colors.style";
import Spaces from "./Spaces.style";

const Variables = css`
  :root {
    ${Colors}
    ${Spaces}
    
    --shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
    --border-radius: 3px;
  }
`;

export default Variables;
