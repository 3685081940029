import styled from "styled-components";

export const BaseErrorMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  
  width: 100%;
  background-color: var(--white);
  text-align: center;
  
  padding-top: 56px;
  padding-right: 20px;
  padding-bottom: 56px;
  padding-left: 20px;
`;

export const ErrorTitle = styled.h2`
  color: var(--grey800);
  text-transform: capitalize;
  font-size: 16px;
  margin-top: var(--space-m);
  margin-bottom: var(--space-s);
`;

export const ErrorDescription = styled.p`
  color: var(--grey800);
  font-size: 14px;
`;

export const ErrorMessage = styled.p`
  color: var(--grey800);
  margin-top: 12px;
  font-size: 14px;
`;
