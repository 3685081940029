import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.navy};
  padding-top: 10%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmptyContainer = styled.div`
  height: 8vw;
  background: ${(props) => props.theme.colors.navy};
`;

export const TitleText = styled.div`
  font-size: 12vw;
  font-weight: bold;
`;

export const SubText = styled.div`
  font-size: 4vw;
  font-weight: 500;
  padding-top: 1%;
`;

export const DescriptionText = styled.div`
  font-size: 2vw;
  padding-top: 2%;
`;
