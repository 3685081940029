import _ from "lodash";
import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useState,
} from "react";
import { RouteComponentProps } from "react-router";

import { Button, Toast } from "../../components";
import { Container } from "../../components/GlobalStyle/GlobalStyle.style";
import {
  ButtonContainer,
  Content,
  PageContainer,
  SearchBoxWrapper,
  TableWrapper,
  TableContainer,
  ToolsContainer,
  LoadMoreButtonContainer,
  TableActionButton,
} from "./FileList.style";
import { BaseTable } from "../../components/baseTable/BaseTable";
import { formatDateTime } from "../../utils/dateTime";
import { TextWithIcon } from "../../components/textWithIcon/TextWithIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatBytes } from "../../utils/formatNumber";

import { loadObjects, deleteObject } from "../../services/apis";
import { getFileIcon } from "../../utils/file";
import { ErrorMessage } from "../../components/messages/ErrorMessage";
import { EmptyMessage } from "../../components/messages/EmptyMessage";
import { Breadcrumb } from "../../components/breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom";
import { SearchBox } from "../../components/searchBox/SearchBox";
import { SearchResultContainer } from "../bucketList/BucketList.style";
import { NoResultMessage } from "../../components/messages/NoResultMessage";
import { Popover } from "../../components/popover/Popover";
import { DeleteModal } from "../../components/modal/deleteModal/DeleteModal";
import { ColumnProps } from "../../components/baseTable/molecules/TableHeader";
import { TableRowObject } from "../../components/baseTable/molecules/TableBody";
import { FileListSidebar } from "./organisms/FileListSidebar";
import { FileListLoadingSection } from "./organisms/FileListLoadingSection";
import { FileListHeader } from "./organisms/FileListHeader";
import { NewFolderModal } from "./organisms/NewFolderModal";

interface MatchParams {
  name: string;
}

interface FileListProps extends RouteComponentProps<MatchParams> {}

interface DataProps {
  etag: string;
  extension: string;
  path: string;
  lastModified: string;
  name: string;
  size: number;
  type: string;
}

export const FileList = (props: FileListProps) => {
  const bucketName = props.match.params.name;

  const { search } = useLocation();
  const currentPath = new URLSearchParams(search).get("path") || "";

  const [data, setData] = useState<Array<any>>([]);
  const [tableRows, setTableRows] = useState<Array<TableRowObject>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isContinuousLoading, setIsContinuousLoading] = useState<boolean>(
    false
  );
  const [error, setError] = useState<any>(null);
  const [shouldShowLoadMore, setShouldShowLoadMore] = useState<boolean>(false);
  const [continuousToken, setContinuousToken] = useState<string>("");
  const [requestUrl, setRequestUrl] = useState<string>("");
  const [popoverIndex, setPopoverIndex] = useState<number>(-1);
  const [currentFilePath, setCurrentFilePath] = useState<string>("");
  const [targetFile, setTargetFile] = useState<string>("");

  // Modal State for creating folder.
  const [isCreatingFolder, setIsCreatingFolder] = useState<boolean>(false);
  const [createResultMessage, setCreateResultMessage] = useState<string>("");
  const [createdFolderName, setCreatedFolderName] = useState<string>("");

  // Modal State for delete files.
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const onSuccessNewFolder = async (folderName: string) => {
    setCreatedFolderName(folderName);
    setCreateResultMessage("Success");
    setIsCreatingFolder(false);
    await loadData();

    const timer = setTimeout(() => {
      setCreateResultMessage("");
      clearTimeout(timer);
    }, 5000);
  };

  const onCancelNewFolder = () => {
    setIsCreatingFolder(false);
    setCreatedFolderName("");
    setCreateResultMessage("");
  };

  const handleDeleteFile = async () => {
    try {
      const response = await deleteObject(bucketName, {
        path: `${currentPath}${targetFile}`,
        type: "folder",
      });

      if (response.status === 200) {
        await loadData();
        setShowDeleteModal(false);
        setPopoverIndex(-1);
        setTargetFile("");
      }
    } catch (err: any) {
      const data: { errorCode: string; errorMessage: string } =
        err.response.data;

      switch (data.errorCode) {
        default:
          return;
      }
    }
  };

  // Searching State
  const [searchValue, setSearchValue] = useState<string>("");

  const columns: Array<ColumnProps> = [
    {
      title: "Name",
      size: "lg",
    },
    {
      title: "Last Modified",
      size: "md",
    },
    {
      title: "Size",
      size: "sm",
    },
    {
      title: "",
      size: "xs",
    },
  ];

  const handleSearchKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const targetUrl = `${currentPath}${searchValue}`;

    if (event.key === "Enter" && requestUrl !== targetUrl) {
      setRequestUrl(targetUrl);
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);

    if (event.target.value === "") {
      setRequestUrl(currentPath);
    }
  };

  const getRedirectUrl = (directory: string): string => {
    let link = `/bucket/${bucketName}?path=${encodeURIComponent(requestUrl)}`;
    link += `${directory}%2F`;

    return link;
  };

  const popoverItems = [
    {
      title: "Manage Permission",
      onClick: () => {
        // Implement onClick here
      },
    },
    {
      title: "Manage Metadata",
      onClick: () => {
        // Implement onClick here
      },
    },
  ];

  const popoverBottomAction = {
    title: "Delete",
    color: "#EB5757",
    onClick: () => {
      setShowDeleteModal(true);
    },
  };

  const onClickPopover = (
    e: MouseEvent<HTMLElement>,
    index: number,
    fileName: string
  ): void => {
    /* Prevent folder browsing */
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setTargetFile(fileName);
    setPopoverIndex(index);
  };

  const onDismissPopover = (e: MouseEvent<HTMLElement>): void => {
    /* Prevent folder browsing */
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setPopoverIndex(-1);
  };

  const onClickLoadMore = (): void => {
    loadData(true);
  };

  const mapTableRows = (): void => {
    if (!data) {
      return;
    }

    const rows = data.map((d: DataProps, index: number) => {
      let type = d.extension; // Trust extension first

      if (!type) {
        type = d.type;
      }

      const filePath = `${requestUrl}${d.name}`;

      return {
        redirectUrl: type === "folder" ? getRedirectUrl(d.name) : undefined,
        isRowActive: currentFilePath === filePath,
        onClick:
          type !== "folder" ? () => setCurrentFilePath(filePath) : undefined,
        onClickPolicy: "firstColumnOnly",
        columns: [
          <TextWithIcon icon={getFileIcon(type)} text={d.name} />,
          type !== "folder" ? formatDateTime(d.lastModified) : "-",
          type !== "folder" ? formatBytes(d.size) : "-",
          <>
            <TableActionButton
              onClick={(e: MouseEvent<HTMLElement>) =>
                onClickPopover(e, index, d.name)
              }
            >
              <FontAwesomeIcon color={"#6C757D"} icon={["fas", "ellipsis-h"]} />
            </TableActionButton>
            <Popover
              isShowing={popoverIndex === index}
              items={popoverItems}
              bottomAction={popoverBottomAction}
              onDismiss={(e: MouseEvent<HTMLElement>) => onDismissPopover(e)}
            />
          </>,
        ],
      };
    });

    // @ts-ignore
    setTableRows(rows);
  };

  const loadData = async (continuous = false) => {
    if (!continuous) {
      setIsLoading(true);
      setShouldShowLoadMore(false);
    } else {
      setIsContinuousLoading(true);
    }

    try {
      const response = await loadObjects(bucketName, {
        limit: 30,
        prefix: requestUrl,
        continuationToken: continuous ? continuousToken : null,
      });

      const loadedData = _.get(response, "data.data");

      setShouldShowLoadMore(_.get(response, "data.isTruncated", false));
      setContinuousToken(_.get(response, "data.continuationToken", ""));

      if (continuous) {
        setData([...data, ...loadedData]);
      } else {
        setData(loadedData);
      }

      mapTableRows();

      setIsLoading(false);
      setIsContinuousLoading(false);
      setError(null);
    } catch (error) {
      setIsLoading(false);
      setIsContinuousLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    document.title = `ByteArk Storage - ${bucketName}`;
    setRequestUrl(currentPath);
  }, [bucketName, currentPath]);

  useEffect(() => {
    setData([]);
    setCurrentFilePath("");
    loadData();
  }, [requestUrl]);

  useEffect(() => {
    mapTableRows();
  }, [data, popoverIndex, currentFilePath]);

  useEffect(() => {
    // @ts-ignore
    window.addEventListener("keydown", (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Escape") {
        setCurrentFilePath("");
      }
    });
  }, []);

  const isEmpty = !isLoading && !error && tableRows && !tableRows.length;
  const isSearching = Boolean(searchValue.length) && requestUrl !== currentPath;
  const isNotFound = isEmpty && isSearching && !error;

  const onClickTableContainer = (e: MouseEvent<HTMLElement>) => {
    const targetElement = e.target as HTMLElement;
    const { classList } = targetElement;
    const isClickedOnTheBlankArea = classList.contains("table-container");
    if (isClickedOnTheBlankArea) {
      setCurrentFilePath("");
    }
  };

  return (
    <PageContainer>
      <FileListHeader bucketName={bucketName} />
      <Container>
        <ToolsContainer>
          <SearchBoxWrapper>
            <SearchBox
              text={searchValue}
              placeholder="Search by prefix name..."
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyDown}
              onDelete={() => {
                setSearchValue("");
                setRequestUrl(currentPath);
              }}
            />
          </SearchBoxWrapper>
          <ButtonContainer>
            <Button
              buttonStyle={"secondary-c"}
              isIconButton
              onClick={() => setIsCreatingFolder(true)}
            >
              <FontAwesomeIcon icon={["fas", "folder-plus"]} />
              New Folder
            </Button>
            <Button isIconButton>
              <FontAwesomeIcon icon={["fas", "cloud-upload"]} />
              Upload
            </Button>
          </ButtonContainer>
        </ToolsContainer>
        {isSearching && (
          <SearchResultContainer>
            {tableRows && tableRows.length !== 0
              ? `${tableRows.length}+`
              : "No"}{" "}
            results for ‘{searchValue}’
          </SearchResultContainer>
        )}
        <Content>
          <TableWrapper>
            <TableContainer
              className={"table-container"}
              onClick={(e: MouseEvent<HTMLElement>) => onClickTableContainer(e)}
            >
              <Breadcrumb baseUrl={`bucket/${bucketName}`} path={currentPath} />
              {Boolean(isNotFound) && (
                <NoResultMessage
                  description={
                    "We couldn't find any buckets matching your search."
                  }
                />
              )}
              {!Boolean(isLoading) && !isEmpty && tableRows && !error && (
                <BaseTable columns={columns} rows={tableRows} />
              )}
              {Boolean(isLoading) && (
                <FileListLoadingSection headerColumns={columns} />
              )}
              {!Boolean(isLoading) && error && (
                <ErrorMessage errorMessage={error.message} />
              )}
              {Boolean(!isSearching) && isEmpty && (
                <EmptyMessage
                  item={"files"}
                  description={"This folder is empty."}
                />
              )}
            </TableContainer>

            {Boolean(shouldShowLoadMore) && !error && (
              <LoadMoreButtonContainer>
                <Button
                  onClick={onClickLoadMore}
                  fullWidth={true}
                  buttonStyle={"secondary-d"}
                  disable={isContinuousLoading}
                >
                  {isContinuousLoading ? "Loading..." : "Load More"}
                  <FontAwesomeIcon
                    color={"#FFF"}
                    icon={["far", "chevron-down"]}
                  />
                </Button>
              </LoadMoreButtonContainer>
            )}
          </TableWrapper>
          <FileListSidebar
            bucketName={bucketName}
            currentPath={currentFilePath}
          />
        </Content>
      </Container>
      <NewFolderModal
        bucketName={bucketName}
        currentPath={currentPath}
        isCreatingFolder={isCreatingFolder}
        onCancel={onCancelNewFolder}
        onSuccess={onSuccessNewFolder}
      />
      <DeleteModal
        isOpen={showDeleteModal}
        title="Confirm delete"
        description={`Are you sure you want to delete "${targetFile}"?`}
        onClose={() => {
          setShowDeleteModal(false);
          setPopoverIndex(-1);
        }}
        onDelete={handleDeleteFile}
      />
      {createResultMessage === "Success" && (
        <Toast
          title="Success"
          description={`Folder "${createdFolderName}" has been created.`}
          onClick={() => setCreateResultMessage("")}
        />
      )}
    </PageContainer>
  );
};
