import { TabContainer, Tab } from "./MainTabs.style";

import { MainTabType, MainTab } from "./TabData";

interface MainTabsProps {
  /**
   * Array of tab
   */
  tabs: MainTab[];
  /**
   * Set active tab
   * @default tabs[0]
   */
  activeTab: MainTabType;
}

export const MainTabs = (props: MainTabsProps) => {
  const { tabs, activeTab = tabs[0] } = props;

  return (
    <TabContainer>
      {tabs.map((tab) => (
        <Tab
          to={tab.redirectUrl}
          isActive={activeTab === tab.label}
          key={`main-tab-${tab.label}`}>
          {tab.label}
        </Tab>
      ))}
    </TabContainer>
  );
};
