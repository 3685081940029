import { BaseErrorMessage } from "../baseErrorMessage/BaseErrorMessage";

interface NoResultMessageProps {
  /**
   * The name of the item that is empty
   * @default data
   * */
  item?: string;
  /**
   * Description
   * @default We couldn’t find any buckets matching ${item}.
   * */
  description?: string;
}

export const NoResultMessage = (props: NoResultMessageProps) => {
  let { item, description } = props;

  if (!description) {
    description = `We couldn’t find any buckets matching ${
      item ? `"${item}"` : "your search"
    }.`;
  }

  return (
    <BaseErrorMessage
      icon={"search"}
      title={`No result found`}
      description={description}
    />
  );
};
