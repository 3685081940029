import styled from "styled-components";
import { sectionHeaderStyle } from "../GlobalStyle/GlobalStyle.style";

export const Container = styled.div`
  background-color: var(--white);
  border-radius: var(--border-radius);
  padding: var(--space-base);
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-base);
`;

export const Title = styled.div`
  ${sectionHeaderStyle}
  color: var(--grey900);
  padding-right: var(--space-base);
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  color: var(--grey800);
  padding: 2px var(--space-s);
  margin-right: -8px;
`;
