import { ChangeEvent, KeyboardEvent } from "react";
import { Button, Modal, ModalCard, TextInput, Toast } from "../..";
import {
  Description,
  InputWrapper,
  SubTitle,
  ItalicDescription,
} from "./CreateBucketModal.style";
import { ButtonContainer } from "../Modal.style";
import { validateBucketName } from "../../../utils/bucket";

interface CreateBucketModalProps {
  bucketName?: string;
  isOpen: boolean;
  createResultMessage?: string;
  createdBucketName?: string;
  onClose: () => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onCreate: () => void;
  onCreateSuccess?: () => void;
}

export const CreateBucketModal = (props: CreateBucketModalProps) => {
  const {
    bucketName = "",
    isOpen,
    createResultMessage = "",
    createdBucketName = "",
    onClose,
    onChange,
    onKeyDown,
    onCreate,
    onCreateSuccess = () => {},
  } = props;
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalCard title="New bucket" onClose={onClose}>
          <Description>
            Please name your bucket wisely, you cannot change this later.
          </Description>
          <InputWrapper>
            <TextInput
              text={bucketName}
              error={
                !["Success", ""].includes(createResultMessage) ||
                (!validateBucketName(bucketName) && bucketName !== "")
              }
              placeHolder="bucketname"
              label={
                !["Success", ""].includes(createResultMessage)
                  ? createResultMessage
                  : validateBucketName(bucketName) || bucketName === ""
                  ? "Bucket name must be between 3 and 63 characters long and can consist only of lowercase letters, numbers, and hyphens (-)."
                  : `Invalid name format. \nBucket name must be between 3 and 63 characters long and can consist only of lowercase letters, numbers, and hyphens (-).`
              }
              onChange={onChange}
              onKeyDown={onKeyDown}
            />
          </InputWrapper>
          <SubTitle>Storage domain</SubTitle>
          <ItalicDescription>{`${
            validateBucketName(bucketName) ? bucketName : "bucketname"
          }.st-th-1.byteark.com`}</ItalicDescription>
          <ButtonContainer>
            <Button
              onClick={onCreate}
              disable={!validateBucketName(bucketName)}
            >
              Create
            </Button>
          </ButtonContainer>
        </ModalCard>
      </Modal>
      {createResultMessage === "Success" && (
        <Toast
          title="Success"
          description={`Bucket "${createdBucketName}" has been created.`}
          onClick={onCreateSuccess}
        />
      )}
    </>
  );
};
