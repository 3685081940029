import styled, { css, keyframes } from "styled-components";

export const PopoverAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const PopoverContainer = styled.div<{ position: string}>`
  position: ${(props) => props.position};
  animation: ${PopoverAnimation} 250ms ease;
  width: 200px;
  border-radius: 5px;
  background-color: var(--white);
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
  z-index: 3;
  right: -81px;
  top: 42px;

  @media screen and (max-width: 1260px) {
    right: -9px;
  }
`;

export const PopoverArrow = styled.div`
  position: absolute;
  left: 0;
  transform: translate3d(89px, 0px, 0px);
  top: -12px;
  display: block;
  width: 32px;
  height: 21px;
  z-index: 3;

  @media screen and (max-width: 1260px) {
    left: unset;
    right: 10px;
    transform: unset;
  }

  :before {
    position: absolute;
    display: block;
    content: "";
    border-style: solid;
    border-color: transparent;
    border-bottom-color: rgba(7, 9, 10, 0.05);
    top: 0;
    border-width: 0 12px 12px;
  }

  :after {
    position: absolute;
    display: block;
    content: "";
    border-style: solid;
    border-color: transparent;
    border-bottom-color: #FFF;
    top: 1px;
    border-width: 0 12px 12px;
  }
`;

export const PopoverItemsWrapper = styled.div<{withBottomAction: boolean}>`
  position: relative;
  width: 100%;
  padding-top: var(--space-s);
  padding-bottom: ${(props) => props.withBottomAction ? '0' : 'var(--space-s)'};
  z-index: 3;
`;

export const PopoverItem = styled.button<{ color?: string }>`
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  
  width: 100%;
  padding: var(--space-s);
  transition: background-color 250ms ease;
  
  ${(props) => props.color && css`color: ${props.color}`};

  :hover {
    background-color: var(--grey200);
  }
`;

export const MenuSeparator = styled.div`
  width: 100%;
  height: 1px;
  margin-top: var(--space-s);
  background-color: var(--grey400);
`;

export const PopoverOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: default;
`;
