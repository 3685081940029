import { BaseErrorMessage } from "../baseErrorMessage/BaseErrorMessage";

export const LoadingMessage = () => {
  return (
    <BaseErrorMessage
      icon={'spinner'}
      title={`Loading`}
    />
  );
};
