const theme = {
  colors: {
    primary: "#00A6ED",
    secondary: "#5613B5",
    secondary2: "#6957C5",
    middle: "#2D83DB",
    primaryLighten: "#62BFF4",
    secondaryLighten: "#735DE5",
    primaryDarken: "#006692",
    secondaryDarken: "#443880",
    primaryOverlay: "rgba(0, 166, 237, 0.1)",
    grey100: "#FBFBFC",
    grey200: "#F8F9FA",
    grey300: "#EEF0F2",
    grey400: "#E8EAEE",
    grey500: "#D0D4D8",
    grey600: "#B1B9C0",
    grey700: "#9EA4A9",
    grey800: "#6C757D",
    grey900: "#343A40",
    white: "#FFFFFF",
    overlay: "#253742",
    overlayHover: "#465562",
    navy: "#212D37",
    navyDarken: "#1A242C",
    lightBlue: "#F0F5F8",
    red: "#EB5757",
    green: "#3FB772",
    yellow: "#F0B542",
  },
  spaces: {
    xl: "48px",
    l: "32px",
    m: "24px",
    base: "16px",
    s: "8px",
    xs: "4px",
  },
  borderRadius: "5px",
};

export default theme;
