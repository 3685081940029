import { ChangeEvent, KeyboardEvent } from "react";
import { Container, Input, Label } from "./TextInput.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface TextInputProps {
  /**
   * text value.
   */
  text: string;
  /**
   * placeholder of this text input.
   */
  placeHolder: string;
  /**
   * hint text or error message.
   */
  label?: string;
  /**
   * display warning if true.
   * @default false
   */
  error?: boolean;
  /**
   * function that triggle when user keydown.
   */
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  /**
   * function that triggle while user typing.
   */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const TextInput = (props: TextInputProps) => {
  const {
    text,
    placeHolder,
    label,
    error = false,
    onChange,
    onKeyDown,
  } = props;
  return (
    <Container>
      <Input
        value={text}
        type="text"
        placeholder={placeHolder}
        error={error}
        onChange={onChange}
        onKeyDown={onKeyDown}
        ref={input => input && input.focus()}
      />
      {label && (
        <Label error={error}>
          {error && (
            <span>
              <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />{" "}
            </span>
          )}
          {label}
        </Label>
      )}
    </Container>
  );
};
