import { useEffect, useState } from "react";
import { Route, useLocation } from "react-router-dom";

import { configAxiosForWebConsole } from "../axios/config";
import { axiosAuthAPI } from "../axios";
import { RouteComponentProps } from "react-router";
import * as React from "react";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { login } from "../store/modules/user";

interface Path {
  path?: Path | readonly Path[] | undefined;
}

interface ProtectedRouteProps {
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
    | undefined;
  path?: string;
  exact?: boolean;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { component, path, exact = false } = props;
  // const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const teamName = useLocation().pathname.split("/")[1];
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const isAuthenticated = async () => {
    configAxiosForWebConsole(teamName);
    const userResponse = await axiosAuthAPI
      .get("me")
      .catch((err) => console.log("Error in getting user data: ", err));

    if (userResponse) {
      // setUser(true);
      const userData = _.get(userResponse, "data");
      await dispatch(login(userData));
      // localStorage.setItem("byteark-user", JSON.stringify(userData));
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    isAuthenticated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamName]);

  return loading ? (
    <></>
  ) : teamName && user ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Route render={() => (window.location.href = `/accounts/byteark/`)} />
  );
};

export default ProtectedRoute;
