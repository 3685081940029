import styled, { css } from "styled-components";

import { Link } from "../link/Link";
import { maxLayout, minLayout } from "../../utils/breakpoint/breakpoint";
import { sectionHeaderStyle } from "../GlobalStyle/GlobalStyle.style";

export const NavBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  width: 100%;
  box-sizing: content-box;

  @media screen and (min-width: 1200px) {
    padding: 12px var(--space-base);
    width: calc(100% - 32px);
  }
`;

export const StorageLogoContainer = styled(Link)`
  ${sectionHeaderStyle};
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  text-decoration: none;

  :hover {
    cursor: pointer;
  }
`;

export const StorageLogo = styled.img`
  width: 27px;
  margin-right: 8px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${maxLayout.md} {
    display: none;
  }
`;

export const ButtonNav = styled(Link)<{ isActive?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.grey600};
  padding: 8px 10px;
  border-radius: 5px;
  height: 20px;
  display: flex;
  align-items: center;
  margin-right: 25px;
  text-decoration: none;

  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.grey200};
    background: ${(props) => props.theme.colors.overlay};
  }
  ${(props) => {
    if (props.isActive) {
      return css`
        background: ${(props) => props.theme.colors.overlay};
        color: ${(props) => props.theme.colors.grey200};
      `;
    }
  }}
`;

export const ProfileImage = styled.div`
  margin-right: 8px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.grey500};
  color: ${(props) => props.theme.colors.navy};
`;

export const ProfileLabel = styled.span`
  font-size: 14px;
  width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.colors.grey400};
  padding-right: 8px;

  @media ${maxLayout.md} {
    font-size: 16px;
    width: 200px;
  }
`;

export const ProfileDropdownWrapper = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  
  svg {
    margin-right: 0;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.white};

  @media ${minLayout.md} {
    display: none;
  }
`;

export const HiddenSideBar = styled.div<{ isActive?: boolean }>`
  width: 300px;
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  z-index: 13;
  transform: translateX(${(props) => (props.isActive ? 0 : "-360px")});
  transition: transform 250ms ease-in-out;
  height: 100%;
  max-height: 100%;
  background: ${(props) => props.theme.colors.navy};
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
`;

export const SidebarButton = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grey500};
  text-decoration: none;
  > :first-child {
    margin-bottom: 8px;
  }
`;

export const SidebarHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  color: ${(props) => props.theme.colors.white};
`;

export const SidebarButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SidebarServiceLabel = styled.span`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 4px;
  color: ${(props) => props.theme.colors.white};
`;

export const SidebarServiceUrl = styled.span`
  font-size: 14px;
  font-style: italic;
  margin-top: 4px;
  color: ${(props) => props.theme.colors.grey700};
`;

export const SidebarServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.navyDarken};
  padding: 16px;
`;

export const SidebarTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.colors.grey900};
  background: ${(props) => props.theme.colors.navyDarken};
  max-height: calc(100% - 200px);
  overflow-y: scroll;
  height: 100%;
`;

export const HiddenContainer = styled.div<{ isActive?: boolean }>`
  display: ${(props) => (props.isActive ? "flex" : "none")};
  flex-direction: column;
  transition: all 2s linear;
`;

export const TabContainer = styled(Link)<{
  type: "main" | "side" | "sub";
  isActive: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 16px;
  border-left: 4px solid transparent;
  padding-left: ${(props) => (props.type === "main" ? "16px" : props.type === "side" ? "48px" : "80px")};
  font-size: ${(props) => (props.type === "sub" ? "16px" : "18px")};
  color: ${(props) => props.theme.colors.grey600};
  text-decoration: none;

  ${(props) => {
    if (props.type === "main" && props.isActive) {
      return css`
        color: ${(props) => props.theme.colors.lightBlue};
        background: ${props.theme.colors.overlay};
      `;
    } else if (props.type === "side" && props.isActive) {
      return css`
        color: ${(props) => props.theme.colors.lightBlue};
        background: ${props.theme.colors.primaryOverlay};
        border-color: ${props.theme.colors.primary};
      `;
    } else if (props.type === "sub" && props.isActive) {
      return css`
        color: ${(props) => props.theme.colors.lightBlue};
        background: ${props.theme.colors.primaryOverlay};
        border-color: ${props.theme.colors.primary};
      `;
    }
  }}
`;

export const SidebarOverLay = styled.div<{ isActive: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${(props) => props.theme.colors.navy};
  opacity: 0.5;
  z-index: ${(props) => (props.isActive ? 12 : -10)};

  ${(props) => {
    if (props.isActive) {
      return css`
        .body {
          position: fixed;
          top: 0px;
        }
      `;
    }
  }}
`;

export const SideTabProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  font-size: 16px;
  z-index: 14;
  color: ${(props) => props.theme.colors.grey400};
  box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.2);

  svg {
    margin-right: 0;
  }
`;

export const SideTabProfileWWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SidebarProfileTab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  color: ${(props) => props.theme.colors.grey600};
  :first-child {
    border-top: 1px solid ${(props) => props.theme.colors.grey900};
  }
  :nth-child(2) {
    border-bottom: 1px solid ${(props) => props.theme.colors.grey900};
  }

  > svg {
    margin-right: 8px;
  }
`;
