import { Button, Modal, ModalCard } from "../..";
import { Text, ButtonContainer } from "./DeleteModal.style";

interface DeleteModalProps {
  /**
   * Title of modal.
   */
  title: string;
  /**
   * Alert message.
   */
  description: string;
  /**
   * Display Modal if true
   * @default false
   */
  isOpen: boolean;
  /**
   * function that triggle when click modal background, cancle or close icon.
   */
  onClose?: () => void;
  /**
   * function that triggle when confirm delete.
   */
  onDelete: () => void;
}

export const DeleteModal = (props: DeleteModalProps) => {
  const { title, description, isOpen, onClose = () => {}, onDelete } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalCard title={title} onClose={onClose}>
        <Text>{description}</Text>
        <ButtonContainer>
          <Button onClick={onClose} buttonStyle={"secondary-a"}>
            Cancel
          </Button>
          <Button onClick={onDelete} buttonStyle={"danger"}>
            Delete
          </Button>
        </ButtonContainer>
      </ModalCard>
    </Modal>
  );
};
