import { useHistory } from "react-router";
import { TableBodyContainer, TableCell, TableRow } from "./TableBody.style";
import {ColumnProps} from "./TableHeader";

export interface TableRowObject {
  /** An array of data, separated by columns */
  columns: Array<any>,
  /** If the row is active, it will be highlighted
   *  @default false */
  isRowActive?: boolean,
  /** An optional function to call when clicking the row */
  onClick?: () => void,
  /**
   * Choose if onClick function will be executed only on the first column or the whole row.
   * This can prevent unintentionally function executing on button clicking.
   * @default 'all' */
  onClickPolicy?: "firstColumnOnly" | "all";
  /** An optional url to go when clicking the row */
  redirectUrl?: string,
}

interface TableBodyProps {
  /** An array of columns' title and settings */
  columns: Array<ColumnProps>,
  /** An array of Table rows */
  rows: Array<TableRowObject>,
}

export const TableBody = (props: TableBodyProps) => {
  const { columns, rows } = props;
  const history = useHistory();

  const onClickRow = (row: TableRowObject, columnIndex: number) => {
    const { redirectUrl, onClick, onClickPolicy = "all" } = row;

    if (onClickPolicy === "firstColumnOnly" && columnIndex > 0) {
      return;
    }

    if (redirectUrl) {
      history.push(redirectUrl)
    }

    if (typeof onClick === 'function') {
      onClick();
    }
  }

  const withLink = (row: TableRowObject, columnIndex: number) => {
    const { redirectUrl, onClick, onClickPolicy = "all" } = row;
    if (onClickPolicy === "firstColumnOnly" && columnIndex > 0) {
      return false;
    }
    return Boolean(redirectUrl) || Boolean(onClick);
  }

  return (
    <TableBodyContainer>
      {
        rows.map((row, rowIndex) => (
          <TableRow
            isRowActive={row.isRowActive || false}
            key={`row${rowIndex}`}>
            {
              row.columns.map((data, columnIndex) => (
                <TableCell
                  onClick={() => onClickRow(row, columnIndex)}
                  className={`table-cell column-${columns[columnIndex].size || "lg"} column${columnIndex + 1}`}
                  key={`row${rowIndex}data${columnIndex}`}
                  withLink={withLink(row, columnIndex)}>
                  {data}
                </TableCell>
              ))
            }
          </TableRow>
        ))
      }
    </TableBodyContainer>
  )
};
