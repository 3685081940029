import styled from "styled-components";
import { maxLayout } from "../../utils/breakpoint/breakpoint";
import { ButtonWrapper } from "../../components/button/Button.style";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--lightBlue);
`;

export const Content = styled.div`
  padding: var(--space-m) 0;

  @media ${maxLayout.md} {
    flex-direction: column;
  }
`;

export const SearchBoxWrapper = styled.div`
  width: 462px;

  @media ${maxLayout.md} {
    width: calc(100% - 155px);
  }

  @media ${maxLayout.sm} {
    width: 100%;
  }
`;

export const SearchResultContainer = styled.div`
  font-size: 14px;
  line-height: 1.4;
  color: var(--grey800);
  margin-bottom: var(--space-s);
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-base);

  ${ButtonWrapper} {
    height: 36px;
    padding: 8px 24px;
  }

  @media ${maxLayout.sm} {
    flex-direction: column;

    ${ButtonWrapper} {
      order: 1;
      width: 100%;
      margin-bottom: var(--space-base);
    }

    ${SearchBoxWrapper} {
      order: 2;
    }
  }
`;
