import _ from "lodash";
import {
  DataMetaContainer,
  FileInfoContainer,
  FileListSidebarContainer,
  GuideContainer,
  InputGroup,
  MetaContent,
  MetaGroup,
  MetaTitle,
  SidebarOverlay,
} from "./FileListSidebar.style";
import { useEffect, useState } from "react";
import { loadFileDetails } from "../../../services/apis";
import { formatBytes } from "../../../utils/formatNumber";
import { formatDateTime } from "../../../utils/dateTime";
import { InputCopy } from "../../../components/inputCopy/InputCopy";
import { ErrorMessage } from "../../../components/messages/ErrorMessage";
import { LoadingPlaceholder } from "../../../components/loadingPlaceholder/LoadingPlaceholder";
import { TextWithIcon } from "../../../components/textWithIcon/TextWithIcon";
import { getFileIcon } from "../../../utils/file";

interface FileInfoProps {
  etag: string,
  extension: string,
  lastModified: string,
  name: string,
  path: string,
  size: number,
  type: string,
  url: string,
}

interface FileListSidebarProps {
  bucketName: string,
  currentPath: string,
}

export const FileListSidebar = (props: FileListSidebarProps) => {
  const { bucketName, currentPath } = props;

  const [fileInfo, setFileInfo] = useState<FileInfoProps | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [isMobileSidebarShowing, setIsMobileSidebarShowing] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      setFileInfo(null);
      if (!currentPath) {
        return;
      }

      setIsLoading(true);
      setError('');
      try {
        const response = await loadFileDetails(bucketName, { path: currentPath });
        setFileInfo(_.get(response, "data"));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(_.get(error, "message"));
      }
    };

    loadData();

    if (currentPath) {
      setIsMobileSidebarShowing(true);
    }
  }, [bucketName, currentPath]);

  return (
    <>
      <FileListSidebarContainer
        hide={!isMobileSidebarShowing}>
        {
          !Boolean(currentPath) && (
            <GuideContainer>
              <h4>Guide</h4>
              <p>You can develop your system, or use Amazon S3-compatible software to manage files.</p>
              <p>
                <a href={''}>How to use desktop clients</a>
              </p>
              <p>
                <a href={''}>How to develop your software using: NodeJS / PHP / Golang</a>
              </p>
            </GuideContainer>
          )
        }
        {
          Boolean(currentPath) && !error && (
            <FileInfoContainer>
              {
                fileInfo ? (
                  <TextWithIcon
                    icon={getFileIcon(fileInfo.extension)}
                    text={fileInfo.name} />
                ) : (
                  <LoadingPlaceholder width={'100%'} height={33.5} />
                )
              }
              <DataMetaContainer>
                <MetaGroup>
                  <MetaTitle>
                    Size
                    {/*{*/}
                    {/*  fileInfo ? 'Size' : (*/}
                    {/*    <LoadingPlaceholder width={'100%'} height={19} />*/}
                    {/*  )*/}
                    {/*}*/}
                  </MetaTitle>
                  <MetaContent>
                    {
                      fileInfo ? (<span>{ formatBytes(fileInfo.size) }</span>) : (
                        <LoadingPlaceholder width={'100%'} height={14} />
                      )
                    }
                  </MetaContent>
                </MetaGroup>
                <MetaGroup>
                  <MetaTitle>
                    Type
                    {/*{*/}
                    {/*  fileInfo ? 'Type' : (*/}
                    {/*    <LoadingPlaceholder width={'100%'} height={19} />*/}
                    {/*  )*/}
                    {/*}*/}
                  </MetaTitle>
                  <MetaContent>
                    {
                      fileInfo ? (<span>{ fileInfo.extension }</span>) : (
                        <LoadingPlaceholder width={'100%'} height={14} />
                      )
                    }
                  </MetaContent>
                </MetaGroup>
                <MetaGroup>
                  <MetaTitle>
                    Last Modified
                    {/*{*/}
                    {/*  fileInfo ? 'Last Modified' : (*/}
                    {/*    <LoadingPlaceholder width={'100%'} height={19} />*/}
                    {/*  )*/}
                    {/*}*/}
                  </MetaTitle>
                  <MetaContent>
                    {
                      fileInfo ? (
                        <span>{ formatDateTime(fileInfo.lastModified) }</span>
                      ) : (
                        <LoadingPlaceholder width={'100%'} height={14} />
                      )
                    }
                  </MetaContent>
                </MetaGroup>
              </DataMetaContainer>
              <InputGroup>
                <p>URL</p>
                <InputCopy value={fileInfo ? fileInfo.url : ''} />
              </InputGroup>
              <InputGroup>
                <p>Entity Tag</p>
                <InputCopy value={fileInfo ? fileInfo.etag : ''} />
              </InputGroup>
            </FileInfoContainer>
          )
        }
        {
          !Boolean(isLoading) && error && (
            <ErrorMessage />
          )
        }
      </FileListSidebarContainer>
      <SidebarOverlay
        hide={!isMobileSidebarShowing}
        onClick={() => setIsMobileSidebarShowing(false)} />
    </>
  );
};
