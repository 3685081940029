import {TableHeaderCell, TableHeaderContainer} from "./TableHeader.style";

export interface ColumnProps {
  /** Text to display */
  title: string,
  /**
   * Column size
   * @default 'lg'
   * */
  size?: 'lg' | 'md' | 'sm' | 'xs',
}

interface TableHeaderProps {
  /** An array of columns' title and settings */
  columns: Array<ColumnProps>,
  /**
   * Add border radius or not?
   * @default true
   * */
  roundedBorder?: boolean,
}

export const TableHeader = (props: TableHeaderProps) => {
  const { columns, roundedBorder = true } = props;

  return (
    <TableHeaderContainer
      roundedBorder={roundedBorder}>
      {
        columns.map((column, columnIndex) => {
          const { size = 'lg', title } = column;
          return (
            <TableHeaderCell
              className={`header-cell column-${size} column${columnIndex + 1}`}
              key={`header-column-${columnIndex}`}>
              { title }
            </TableHeaderCell>
          )
        })
      }
    </TableHeaderContainer>
  );
};
