import { ChangeEvent, KeyboardEvent } from "react";
import { Container, Input, IconWrapper } from "./SearchBox.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface SearchBoxProps {
  /**
   * typing result.
   */
  text?: string;
  /**
   * place holder of this search box.
   */
  placeholder?: string;
  /**
   * function that fire when click X that was appear when typing.
   */
  onDelete?: () => void;
  /**
   * function that fire when key down.
   */
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  /**
   * function that fire when typing.
   */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBox = (props: SearchBoxProps) => {
  const { text = "", placeholder = "", onChange, onKeyDown, onDelete } = props;
  return (
    <Container>
      <Input
        type="search"
        value={text}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <IconWrapper>
        {onDelete ? (
          <FontAwesomeIcon
            size="xs"
            icon={["fas", `${text === "" ? "search" : "times"}`]}
            onClick={onDelete}
          />
        ) : (
          <FontAwesomeIcon
            size="xs"
            icon={["fas", "search"]}
            onClick={onDelete}
          />
        )}
      </IconWrapper>
    </Container>
  );
};
