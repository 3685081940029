import styled from "styled-components";
import { TableCellStyles } from "../BaseTable.style";

export const TableHeaderContainer = styled.div<{ roundedBorder: boolean }>`
  position: relative;
  background-color: var(--grey300);
  border-bottom: 1.5px solid var(--grey500);
  border-bottom-left-radius: ${(props) => props.roundedBorder ? 'var(--border-radius)' : '0'};
  border-bottom-right-radius: ${(props) => props.roundedBorder ? 'var(--border-radius)' : '0'};
  
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TableHeaderCell = styled.div`
  ${TableCellStyles};
  border-radius: 0;
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
`;
