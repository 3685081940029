import styled, { keyframes } from "styled-components";
import { maxLayout } from "../../utils/breakpoint/breakpoint";
import { ButtonWrapper } from "../../components/button/Button.style";
import { TableRow } from "../../components/baseTable/molecules/TableBody.style";

export const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${(props) => props.theme.colors.lightBlue};
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: var(--space-m);

  @media ${maxLayout.md} {
    flex-direction: column;
  }

  @media ${maxLayout.sm} {
    .column2 {
      display: none;
    }
    .column3 {
      white-space: pre;
    }
  }

  @media ${maxLayout.xxs} {
    .column3 {
      display: none;
    }
  }
`;

export const TableWrapper = styled.div`
  width: calc(100% - 330px);

  @media ${maxLayout.md} {
    width: 100%;
  }
`;

export const TableContainer = styled.div`
  min-height: calc(100vh - 360px);
  background-color: var(--white);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);

  ${TableRow} {
    animation: ${FadeIn} 120ms ease;
  }

  .column2 {
    width: 260px;
  }

  .column3 {
    text-align: right;
    width: 140px;
  }

  .column4 {
    width: 60px;
    padding: 0;
  }
`;

export const SearchBoxWrapper = styled.div`
  width: 462px;

  @media ${maxLayout.sm} {
    width: 100%;
    order: 2;
  }
`;

export const ToolsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-m);
  margin-bottom: var(--space-base);

  ${ButtonWrapper} {
    height: 36px;
    padding: var(--space-s) var(--space-m);
    margin-left: var(--space-base);

    svg {
      margin-right: 6px;
    }
  }

  @media ${maxLayout.sm} {
    width: 100%;
    flex-wrap: wrap;
    order: 1;

    ${ButtonWrapper} {
      margin-left: 0;
      width: 48%;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;

  @media ${maxLayout.sm} {
    width: 100%;
    justify-content: space-between;
    margin-bottom: var(--space-base);
  }
`;

export const LoadMoreButtonContainer = styled.div`
  margin-top: var(--space-s);
  width: 100%;

  svg {
    margin-left: var(--space-s);
    margin-right: 0;
  }
`;

export const TableActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;

  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  z-index: 2;

  width: 100%;
  height: 100%;
`;
